<div class="internalPageTp1">
    <div class="HdTpPg1">
        <div class="container">
            Risk Warnings
        </div>
    </div>
    <div class="cont-box-1">
        <div class="container">
            <div class="content-text">
                
<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Introduction</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Investing in capital markets is associated with various risks. It is essential to develop an understanding of the risks of investing, financial instruments and financial services.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">The purpose of this document is to convey such an understanding. You should always remember that</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">the value of your investments and any income from them can decrease or increase over time;<br>
you may get back less than the amount you originally invested;<br>
past performance is not an indicator of future performance;<br>
nothing is certain in capital markets; and<br>
both prices of investments and income distributions may fluctuate significantly.<br>
Fundamentals of Investing</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Risks</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Risk is about the probability and possible extent of losses of an investment. An investor must accept the possibility of taking losses in the investment process.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Relationship between Return, Security and Liquidity</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">To be able to choose an investment strategy you should understand the importance of the three pillars of successful investing:</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Return is the measure of economic success of an investment which is expressed in gains and losses.<br>
Security aims at preserving the value of the investment. The security of an investment depends on the risks associated with that investment.<br>
Liquidity describes the degree to which an asset or security can be quickly bought or sold in the market without affecting the asset’s price.<br>
Return, security and liquidity are inherently linked. A secure and liquid investment will as a general rule not generate high returns. A secure investment generating relatively high returns will probably not be liquid. A liquid investment generating high returns will regularly provide a low security. All in all, an investor must weigh these goals against each other depending on his/her individual preferences as well as personal and financial circumstances.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Managing Portfolio Risk</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Although it is not possible to predict what is going to happen in the future, it is possible to manage your exposure to different risks within your investment portfolio, to a certain extent. Our role is to design portfolios that can withstand shocks whilst still offering the opportunity for returns. However, market conditions may limit our ability to trade certain assets in your portfolio. Diversification is one way in which portfolio risk is managed. This is because different classes of assets are affected by different risks to different degrees. If the risk is spread across many different assets and asset classes, it is unlikely to affect all at the same time and to the same degree.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">General Risks</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">There are general risks to which all asset classes, financial instruments and financial services are exposed to and which may lead to a financial loss. Some of these risks are:</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Economic risk: The economic development moves in cyclical fluctuations. Cyclical downturns can reduce the value of your investment substantially.<br>
Inflation risk: Money is subject to decrease in value due to inflation.<br>
Country risk: The government of a country may exert influence on capital movements and the transferability of its currency and, thus, hindering a debtor to fulfil its obligations. If your investment includes assets affected by this risk, you might suffer a loss.<br>
Currency risk: This is a form of risk that arises from the change in price of one currency against another. Your investment might decrease in value even though the underlying asset has not decreased in value.<br>
Liquidity risk: Some investments may not be liquid and, thus, may not be sold ad hoc or sold only with reduction in value. If these investments must be sold on short notice, you might suffer a loss.<br>
Cost risk: Banks, credit institutions and financial services companies charge various costs which may substantially reduce the performance of your investment over time.<br>
Tax risk: Gains generated by investments in capital markets are subject to taxes and/or other fiscal liabilities. Changes of the law might lead to an unexpected value decrease of your investment.<br>
Risk of leveraged investments: Leveraged investments lead to increased risks in investing. If your investment decreases in value, you might not be able to cover interest or repayment claims.<br>
Risk of incorrect information: You may make misguided investment decisions due to missing, incomplete or incorrect information and, thus, suffer a loss.<br>
Risk of self-custody: Self-custody increases the risk of losing the documents underlying the relevant security and missing important deadlines (as no custodian keeps track of them).<br>
Risk of custody abroad: Securities purchased abroad are often kept safe by third parties chosen by the custodian. This may lead to higher costs, longer terms of delivery and uncertainties regarding foreign jurisdictions. In particular, in case of insolvency of or other measures of enforcement against the third party safekeeping the securities the access to the securities might be impeded or made impossible.<br>
Risks of Different Asset Classes</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Shares</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Shares constitute an equity stake in a company. The value of shares is derived from dividing the value of a company by the number of shares which it has issued. Owning shares does not mean that the shareholder has direct control over the day-to-day operations of the company, however, depending on the class of share, it generally entitles to an equal distribution in any profits declared in the form of dividends. Shares are, inter alia, subject to the following risks:</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Price risk: The price of a share is determined by supply and demand and may be influenced by the general economic risk as well as specific risks pertaining to the company itself. This may lead to a decrease in the value of the share. The development cannot be systematically predicted.<br>
Insolvency risk: As shareholders’ claims are generally the last ones fulfilled in case of insolvency, shares are considered a high-risk investment.<br>
Dividend risk: A dividend is a payment made by a company to its shareholders, usually as a distribution of profits. Due to a poor economic development of the company or a decision of the board not to disburse dividends, a shareholder may not receive any dividends.<br>
Risk of changes in interest rate: Prices of shares might be substantially affected by changing interest rates.<br>
Liquidity risk: Prices for shares traded on exchanges are usually available on daily basis. However, for various reasons trading might be temporarily disrupted and, thus, you might not be able to sell shares on short notice.<br>
Bonds</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">The term bonds refers to a wide range of interest bearing securities freely tradable. Bonds are issued by companies, public institutions and/or governments. In contrast to shares, bonds do convey an interest. The entity which issues the bond (issuer) is effectively taking out a loan, under which it agrees to pay a fixed or floating rate of interest (coupon), and the full value of the bond (principal) when it reaches maturity. Though the value at maturity remains constant, the tradable value is susceptible to change. This can be influenced by interest rates, inflation and the outlook of the creditworthiness of the corporation or country which issued the bond. Bonds are, inter alia, subject to the following risks:</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Counterparty risk: If the issuer of bonds is partially, or in total, unable or fails to honour its obligations, the investment may suffer a corresponding loss. As secured financial instruments, covered bonds, address this risk.<br>
Inflation risk: If inflation and/or inflation expectations increase, then the value of the investment may decrease and the investor may ultimately suffer a loss.<br>
Risk of changing interest rates: The market interest rate is material for the value of a bond, because bonds might become less economically attractive in times of increasing interest rates and, thus, decrease in value. If sold before maturity, the owner may suffer a loss.<br>
Commodities</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">A commodity is a basic resource which can be used in the production of another good or service. Commodities are split into four major classes ‒ agriculture, energy, metals and livestock. Investments in commodities are referred to as alternative investments. In contrast to shares and bonds, trading of commodities is usually taking place without ownership in commodities actually being transferred. Rather commodities are replicated by derivatives which then in turn are traded. By trading through derivatives, hurdles such as transport, storage, insurance of goods are set aside and only the value of the commodity is being traded. However, it adds the dimension of counterparty risk to the risk profile. If the investor wants to invest in a single commodity, he/she must purchase a security which reproduces the performance of that commodity (Exchange Traded Commodity; ETC). As with ETFs, ETCs are traded on exchanges. However, there is an important difference: The capital invested in ETCs does not form part of the fund’s assets which are protected from insolvency of the issuer. Thus, ETCs are always subject to a counterparty risk against which the issuers implement different hedging mechanisms. The relevant factors for choosing ETFs explained below apply accordingly to ETCs. Commodities are, inter alia, subject to the following risks:</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Economic risk: In general, synthetic investments in commodities are subject to the same market fluctuations as direct investments in commodities. Prices of commodities are influenced besides general market movements by a wide range of other factors and, thus, are generally considered as being rather volatile and high-risk.<br>
Counterparty risk: If the issuer of a commodity derivative is partially or in total unable or fails to honour its obligations from the derivatives contract, the investment may suffer a corresponding loss.<br>
Foreign Exchange</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Investments denominated in a foreign currency are a possibility to diversify your portfolio. In addition, investments in all other asset classes might be associated with foreign currency risks. Investments in foreign currency are, inter alia, subject to the following risks:</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Exchange rate risk: Changes in the exchange rate of different currencies may have a substantial influence on the performance of an investment. Even in the event of the investment performing well, the value might deteriorate for individual investors due to unfavourable exchange rates.<br>
Risk of changing interest rates: Changes in the rate of interest in the investors’ domestic market or foreign market may cause changes in the exchange rate due to considerable capital movements.<br>
Regulatory risk: Regulatory authorities (e.g. Central Banks) play a decisive role in the fixing or management of its country’s exchange rate. They might intervene for macroeconomic reasons. This poses additional risks hard to foresee for the individual investor.<br>
Real Estate</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Real Estate as an asset class may comprise investments in residential, commercial as well as special purpose real estate. The investment may be made directly by acquiring real property or indirectly by investing in real estate funds, REITs or real estate companies. Investments in real estate are, inter alia, subject to the following risks:</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Return risk: The (direct) investment in real estate requires substantial financial resources. Amortisation takes place by renting out the real estate. This periodic return can easily be interrupted which may endanger the timely amortisation of the investment.<br>
Valuation risk: The valuation of real estate is subject to various uncertainties. In addition, there are regionally segmented markets. Thus, there is substantial risk of paying to much for the purchase of a property or changing valuations due to a change in the environment.<br>
Liquidity risk: Real estate is a relatively illiquid asset class. The sale process might take a considerable period of time.<br>
Transaction costs: The process of valuation, sale / purchase and transfer causes relatively high costs compared to investments in securities.<br>
Exchange rate risk: Indirect investments in real estate may be subject to the general exchange rate risks.<br>
Open-Ended Funds and ETFs in Particular</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Open-ended funds are a collective investment scheme which can issue and redeem shares at any time. It contrasts with a closed-end fund, which typically issues all available shares at the launch of the fund, with such shares usually only being tradable between investors thereafter. Exchange Traded Funds (ETFs) are a special form of an open-ended fund. ETFs are a passive investment instrument which seek to track the performance of a particular index, such as the FTSE 100. They provide exposure to the performance of a pool of stocks, bonds or other asset classes included in the index, as well as different regions and sectors. Shares of ETFs are traded on an exchange and, thus, are rarely purchased from the issuer itself.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Major Characteristics of ETFs</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Diversification: ETFs provide simple and easy means of diversification. They do so as an investment in an ETF is akin to buying a small proportion of each component which makes up the relevant index. Since this takes place in a single transaction, considerable time and transaction costs are saved.<br>
Low Costs: The total expense ratio (TER) of ETFs are significantly lower than those of traditional funds.<br>
High Liquidity and Transparency: During exchange hours an ETF is continuously priced and traded, meaning it is liquid, provided the ETF is well established and of big enough size. Furthermore, the constituents of the ETF can be monitored and its performance can be compared to the benchmark index.<br>
Major Factors for the Selection of ETFs</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Low Costs: The costs of an ETF as indicated by the TER among others (external trade costs, bid-ask spreads, taxes, etc.) should be considered.<br>
High Liquidity: Certain ETFs are more liquid than others. An investor should pick ETFs which can be easily and readily traded.<br>
Method of Replication: Many ETFs synthetically replicate an index by means of derivatives. This means of replication often also involves a counterparty risk. Often ETFs which physically reproduce an index are preferred due to this more robust form of replication.<br>
Low Tracking Error: ETFs which closest align to the performance of the index should be chosen.<br>
Diversification: An ETF replicates a specific index. A broad diversification generally reduces risk, but may also lead to higher trading costs (i.e. in illiquid assets). When choosing an ETF one should consider the ones with a healthy balance between diversification and appropriate trading costs.<br>
Risks of ETFs</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Underlying Price Risk: The price of ETFs will fluctuate, reflecting changes in the value of the underlying assets or derivatives, so the value of your investment may increase or decrease. If a large amount of derivatives are used, the risk profile of the ETF may differ significantly from physically replicating ETFs.<br>
Counterparty risk: If the counterparty of a derivative contract gets into distress, the value of an ETF investment might be affected. Providers may take measures such as using multiple counterparties or holding collateral, so if one counterparty defaults they can draw the collateral to pay returns to investors.<br>
Tracking error: ETFs possibly underperform the index or benchmark they are tracking over the longer term because of the impact of fees and other costs The tracking error varies between different ETFs depending on the approach chosen to replicate the index.<br>
Currency: Your portfolio will be valued in GBP. If an ETF’s underlying investments are in a currency different to the denominated currency of the ETF, there will be embedded foreign exchange risk meaning that, for example, an index might rise but its currency might fall against GBP resulting in a different return for the ETF investor.<br>
Tax: Tax is subject to change that could affect your investment in the future. In some cases, you may incur income tax on your proceeds when you sell rather than capital gains tax.<br>
Market Timing: As ETFs trade continuously during exchange opening hours there is a risk that ETF transactions are executed outside of the normal market hours of the underlying fund constituents. This could affect the performance of the ETF relative to the index constituents.<br>
Liquidity: Some ETFs or their underlying fund constituents might be traded with limited volumes. There is a risk that transactions to buy or sell such securities might have a negative impact on their prices. In addition, a lack of liquidity can lead to a delay in the execution of transactions or may limit the extent to which a transaction can be executed. ETF trading may also be suspended due to the closure of the underlying market or due to the fund winding down.<br>
Risks of Financial Services</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">For investing in capital markets, different financial services are available in the market. All of them are subject to of certain risks and conflicts of interest.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Execution only trading: The client makes his/her own investment decisions which are merely executed by the financial service provider. The client does not explain his/her circumstances to the financial service provider and the financial service provider does not give advice related to these transactions. The client risks making misguided investment decisions.<br>
Investment advice: Investment advice is a personal recommendation that attempts to assist an investor regarding particular financial products and investment strategies. However, the adviser does not have ultimate discretion to execute the transactions. Some advisors are not fully independent and offer only restricted advice. This is where they advise on only a small portion of the total available market.<br>
Investment management: An investment manager has discretion over both asset allocation and individual security selection in relation to the assets held in the client’s portfolio. Before providing the investment management services, the investment manager must assess the client’s individual risk preferences as well as personal and financial circumstances. The investment manager shall act in the client’s best interest. However, the investment manager may make misguided investment decisions or even behave fraudulently.</p>
              </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mainbannerresources',
  templateUrl: './mainbannerresources.component.html',
  styleUrls: ['./mainbannerresources.component.scss']
})
export class MainbannerresourcesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

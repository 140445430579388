import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import {NgxTypedJsModule} from 'ngx-typed-js';
export function playerFactory() {
  return player;
}

import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { ResourcesComponent } from './resources/resources.component';
import { PartnersComponent } from './partners/partners.component';
import { AbakastoryComponent } from './components/abakastory/abakastory.component';
import { GetintouchComponent } from './components/getintouch/getintouch.component';
import { Heroslider1Component } from './components/heroslider1/heroslider1.component';
import { Testimonial1Component } from './components/testimonial1/testimonial1.component';
import { Slider1Component } from './components/slider1/slider1.component';
import { SuccessmetricsComponent } from './components/successmetrics/successmetrics.component';
import { OurprtnersComponent } from './components/ourprtners/ourprtners.component';
import { Homevideo1Component } from './components/homevideo1/homevideo1.component';
import { Homevideo2Component } from './components/homevideo2/homevideo2.component';
import { MainsliderComponent } from './components/contactus/mainslider/mainslider.component';
import { DescriptionboxComponent } from './components/contactus/descriptionbox/descriptionbox.component';
import { LeadershipComponent } from './components/contactus/leadership/leadership.component';
import { ThemanagementComponent } from './components/contactus/themanagement/themanagement.component';
import { InvestorsComponent } from './components/contactus/investors/investors.component';
import { CareeerComponent } from './components/contactus/careeer/careeer.component';
import { AboutlocationComponent } from './components/contactus/aboutlocation/aboutlocation.component';
import { ContactareaComponent } from './components/contactus/contactarea/contactarea.component';
import { MainbannerComponent } from './components/solutions/mainbanner/mainbanner.component';
import { SolutiongridsComponent } from './components/solutions/solutiongrids/solutiongrids.component';
import { SolutiongetintouchComponent } from './components/solutions/solutiongetintouch/solutiongetintouch.component';
import { MainbannerresourcesComponent } from './components/resources/mainbannerresources/mainbannerresources.component';
import { OurblogsblockComponent } from './components/resources/ourblogsblock/ourblogsblock.component';
import { OurnewsblockComponent } from './components/resources/ournewsblock/ournewsblock.component';
import { EventsblockComponent } from './components/resources/eventsblock/eventsblock.component';
import { EventsformComponent } from './components/resources/eventsform/eventsform.component';
import { MainblockComponent } from './components/partners/mainblock/mainblock.component';
import { OurpartnersblockComponent } from './components/partners/ourpartnersblock/ourpartnersblock.component';
import { BecomeapartnerComponent } from './components/partners/becomeapartner/becomeapartner.component';
import { TechalliancesComponent } from './components/partners/techalliances/techalliances.component';
import { PartnersformComponent } from './components/partners/partnersform/partnersform.component';
import { Solutiondetail1Component } from './components/solutionsdetailspage/solutiondetail1/solutiondetail1.component';
import { Solutiondetail2Component } from './components/solutionsdetailspage/solutiondetail2/solutiondetail2.component';
import { Solutiondetail3Component } from './components/solutionsdetailspage/solutiondetail3/solutiondetail3.component';
import { Solutiondetail4Component } from './components/solutionsdetailspage/solutiondetail4/solutiondetail4.component';
import { Solutiondetail5Component } from './components/solutionsdetailspage/solutiondetail5/solutiondetail5.component';
import { WhatshouldiknowComponent } from './components/whatshouldiknow/whatshouldiknow.component';
import { RiskwarningsComponent } from './components/riskwarnings/riskwarnings.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { ComponentscookispolicyComponent } from './componentscookispolicy/componentscookispolicy.component';
import { CookiespolicyComponent } from './components/cookiespolicy/cookiespolicy.component';
import { TermsandconditionComponent } from './components/termsandcondition/termsandcondition.component';
import { FaqComponent } from './components/faq/faq.component';
import { BlogdetailpageComponent } from './components/blogdetailpage/blogdetailpage.component';
import { HttpClientModule } from '@angular/common/http';
import { WelcomemessageComponent } from './components/common/welcomemessage/welcomemessage.component';
import { CookietabComponent } from './components/common/cookietab/cookietab.component';
@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    NavbarComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    AboutusComponent,
    SolutionsComponent,
    ResourcesComponent,
    PartnersComponent,
    AbakastoryComponent,
    GetintouchComponent,
    Heroslider1Component,
    Testimonial1Component,
    Slider1Component,
    SuccessmetricsComponent,
    OurprtnersComponent,
    Homevideo1Component,
    Homevideo2Component,
    MainsliderComponent,
    DescriptionboxComponent,
    LeadershipComponent,
    ThemanagementComponent,
    InvestorsComponent,
    CareeerComponent,
    AboutlocationComponent,
    ContactareaComponent,
    MainbannerComponent,
    SolutiongridsComponent,
    SolutiongetintouchComponent,
    MainbannerresourcesComponent,
    OurblogsblockComponent,
    OurnewsblockComponent,
    EventsblockComponent,
    EventsformComponent,
    MainblockComponent,
    OurpartnersblockComponent,
    BecomeapartnerComponent,
    TechalliancesComponent,
    PartnersformComponent,
    Solutiondetail1Component,
    Solutiondetail2Component,
    Solutiondetail3Component,
    Solutiondetail4Component,
    Solutiondetail5Component,
    WhatshouldiknowComponent,
    RiskwarningsComponent,
    PrivacypolicyComponent,
    ComponentscookispolicyComponent,
    CookiespolicyComponent,
    TermsandconditionComponent,
    FaqComponent,
    BlogdetailpageComponent,
    WelcomemessageComponent,
    CookietabComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LottieModule.forRoot({ player: playerFactory }),
    NgxTypedJsModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
@Component({
  selector: 'app-solutiondetail5',
  templateUrl: './solutiondetail5.component.html',
  styleUrls: ['./solutiondetail5.component.scss']
})
export class Solutiondetail5Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  options1: AnimationOptions = {
    path: '/assets/webcanvas/section 1.json',
  };
  options2: AnimationOptions = {
    path: '/assets/webcanvas/section 2.json',
  };
  options3: AnimationOptions = {
    path: '/assets/webcanvas/section 4.json',
  };
  options4: AnimationOptions = {
    path: '/assets/webcanvas/section 5.json',
  };
  options5: AnimationOptions = {
    path: '/assets/webcanvas/section 7.json',
  };
  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

}

<div class="newsBlock">
    <div class="HdStyle1 wow fadeInUp text-center" data-wow-duration="1s" data-wow-delay="0.5s">
        ABAKA In <span>The News</span>
    </div>
    <div class="NewsSliderTp1">
        <div class="container">
            <div class="owl-carousel owl-theme newsSlider">
                <div class="item" *ngFor="let bl of blogs">
                    <div class="newsBlockTp1 text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                        <img class="mainNwsImg" src="{{bl.photo}}" />
                        <div class="newsblockCnt1">
                            <div class="NewBlkLog wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                                <img src="../../../../assets/images/blogthumb.png" alt="">
                            </div>
                            <div class="NewBlkTxt1">{{bl.date}}</div>
                            <div class="NewBlkTxt2">
                                {{bl.title}}
                            </div>
                            <div class="NewBlkTxt3">
                                {{bl.content}}
                            </div>
                            <div class="NewBlkTxt4">
                                <a target="_blank" href="{{bl.external_url}}">Read more</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="item">
                    <div class="newsBlockTp1 text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                        <img class="mainNwsImg" src="../../../../assets/images/newsImage.jpg" />
                        <div class="newsblockCnt1">
                            <div class="NewBlkLog wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                                <img src="../../../../assets/images/blogthumb.png" alt="">
                            </div>
                            <div class="NewBlkTxt1">7 JUN 2021</div>
                            <div class="NewBlkTxt2">
                                Abaka’s AI-powered Next Best Actions app launches on Finastra’s
                                FusionFabric.cloud
                            </div>
                            <div class="NewBlkTxt3">
                                Abaka, an AI driven digital savings and retirement solutions platform, today announced
                                that its.... Abaka’s…keep selling your products and services in a way that’s compliant
                                with the new rules?
                            </div>
                            <div class="NewBlkTxt4">
                                <a routerLink="/blogdetailpage">Read more</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="newsBlockTp1 text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                        <img class="mainNwsImg" src="../../../../assets/images/newsImage.jpg" />
                        <div class="newsblockCnt1">
                            <div class="NewBlkLog wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                                <img src="../../../../assets/images/blogthumb.png" alt="">
                            </div>
                            <div class="NewBlkTxt1">7 JUN 2021</div>
                            <div class="NewBlkTxt2">
                                Abaka’s AI-powered Next Best Actions app launches on Finastra’s
                                FusionFabric.cloud
                            </div>
                            <div class="NewBlkTxt3">
                                Abaka, an AI driven digital savings and retirement solutions platform, today announced
                                that its.... Abaka’s…keep selling your products and services in a way that’s compliant
                                with the new rules?
                            </div>
                            <div class="NewBlkTxt4">
                                <a routerLink="/blogdetailpage">Read more</a>
                            </div>
                        </div>
                    </div>
                </div> -->
                
            </div>
        </div>
    </div>
</div>

<div class="internalPageTp1">
    <div class="HdTpPg1">
        <div class="container">
           Terms & Conditions
        </div>
    </div>
    <div class="cont-box-1">
        <div class="container">
            <div class="content-text">
                
<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Dated:  01 April 2020</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">This agreement (together with our Privacy Policy and Cookies Policy from time to time) sets out the terms and conditions on which you may access and use the intelligent savings platform known as ‘ABAKA’, the data supplied with such platform and the associated website, content and services (“Platform”). The terms of our Privacy Policy and Cookie Policy are incorporated into these Terms and apply to the Platform.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">The Platform is owned and operated by ABAKA Holdings Limited, incorporated and registered in England and Wales with company number 09943429 and whose registered office is at 111 Buckingham Palace Road, London, SW1W 0SR, United Kingdom (“ABAKA”, “we”, “our” or “us”).</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">By accessing and/or registering to use the Platform, you agree to the terms and conditions of this agreement which will bind you. If you do not agree to the terms of this agreement, you must not access or use the Platform.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">To power the Platform we may work with certain service providers (the “Service Providers”).</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">The terms of this agreement apply to the Platform, including any updates or supplements to the Platform, unless they come with separate terms, in which case those terms apply.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">This policy (together with our End User Access Agreement and any other documents referred to in it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Please read the following carefully to understand the types of information we collect from you, how we use that information and the circumstances under which we will share it with third parties. By visiting our website www.abaka.me and the intelligent savings platform known as ‘ABAKA’ (together, the “Platform”) you are accepting and consenting to the practices described in this policy.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">For the purpose of the Data Protection Act 1998 (Act), the data controller is ABAKA. Our ICO Registration Number is: ZA197872.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">1. ELIGIBILITY</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">1.1 To be eligible to access and use the Platform, you must:</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">be at least 18 years old;<br>a resident of the United Kingdom; or a non UK resident and you are not a US citizen and we are able to confirm your identity and status<br>be a duly authorised employee, agent or consultant of a corporate customer that has contracted with ABAKA for use of the Platform (“Corporate Customer”); and<br>agree to the terms and conditions of this agreement.<br>1.2 By using the Platform you warrant that you are at least 18 years old and a resident of the United Kingdom, or a non UK resident and non US citizen.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">2. ACCESS AND USE</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">2.1 Right to access and use the Platform:</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">2.1.1 Your right to access and use the Platform has been granted to you by the Corporate Customer pursuant to its agreement with ABAKA relating to the same.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">2.1.2 If the right to access and use the Platform has not been granted to you by a Corporate Customer of ABAKA Holdings ltd, you will also be subject to the Freemium Services Agreement as per clause 12.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">2.2 You agree that this right is subject, at all times, to your compliance with the terms and conditions of this agreement, any and all terms and conditions imposed on you by the Corporate Customer and any cancellation or suspension of the Platform user account granted to you by the Corporate Customer if applicable.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">2.3 You grant to us and our Service Providers a non-exclusive, royalty-free licence to use the End User Information for all purposes connected with the Platform or referred to in these Terms or the Privacy Policy, with the right to use, modify, display, distribute and create new material using or incorporating the End User Information for those purposes (the “Permitted Uses”).</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">2.4 You warrant that:</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">you own the End User Information; or<br>you are entitled to authorise us and our Service Providers, without any time limit and without the payment of any fees, to use the End User Information for the Permitted Uses.<br>2.5 Any End User Information which you submit via or in connection with the Platform must:</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">be accurate, complete and kept up to date;<br>comply with applicable law in the UK and any other country it originates from.<br>not be defamatory, deceptive or misleading;<br>not be harmful to the Platform;<br>not infringe any copyright, database right or trade mark of any person; or<br>breach any legal duty owed to a third party, such as a duty of confidence.<br>2.6 By using the Platform, you authorise us and our Service Providers as your representative on your behalf and in your name to:</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">access and retrieve End User Information held by Account Providers (including to do all things necessary for that activity, such as accessing any third party internet sites, servers or documents on or in which such End User Information is held); and<br>register on your behalf for accounts with Account Providers.<br>When you add an external provider for aggregation, you will need to input the external security details you usually use to log in to the external providers’ sites. This might include things like your username, password or passcode and other login information.<br>We may pass some of your personal information to third party Service Providers, where we rely on these providers to add an account.<br>Your external security details will be stored on a third party secure server and not on your mobile phone. Your external security details will be encrypted when stored and encrypted when data is transmitted.<br>2.7 You agree that when we retrieve your End User Information in the way described in paragraph 2.6, we are doing so as your representative and on your behalf and not on behalf of or in the name of any third party.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">2.8 You agree that we and our Service Providers may rely on the permissions, licences and authorisations set out in these Terms.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">2.9 You agree that we will be entitled to disclose your identity and End User Information to third parties if we are required to do so by any applicable law or court order.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">2.10 We may, in our absolute discretion, remove all or any of your End User Information from our Platform.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">2.11 As between us and our Service Providers, we control all of your End User Information.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">How does it work?</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">2.12 The Platform offers a range of services, including aggregation of your linked accounts (aggregation), analysing your earning and spending patterns (profile building), and making suggestions as to how you might adjust your spending (saving suggestions).</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">2.13 You let us know which external providers you want to link to your profile from our list.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">2.14 Each of your accounts with your chosen external providers is added to your “Accounts” area in the Platform as a linked account.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">2.15 You input the external security details used to access your linked accounts online.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">2.16 In conjunction with our Service Providers, we collect and organise information about your linked accounts (your account information) from the sites of the external providers. To do this, we will need to exchange some of your personal information with our Service Providers if necessary.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">2.17 You will be able to see the account information displayed in the Platform.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">2.18 The information we collect during account aggregation is analysed. We make various calculations that enable us to develop a useful financial profile for you.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">2.19 The profile is presented to you in various ways in the Platform</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">2.20 The information we collect during account aggregation is analysed to identify areas where we think you may be able to save money.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">2.21 These potential money-saving opportunities can be presented in the Platform.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">3. PLATFORM RESTRICTIONS</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">3.1 You shall not:</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">except as may be allowed by any applicable law which is incapable of exclusion hereunder:<br>attempt to reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the software subsisting in the Platform; or<br>attempt to copy, modify, duplicate, create derivative works from, frame, mirror, download, transmit, or distribute all or any portion of the software subsisting in the Platform in any form or media or by any means; or<br>access all or any part of the Platform in order to build a product or service which competes with the Platform; or<br>use the Platform to provide services to third parties; or<br>license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the Platform available to any third party, or<br>attempt to obtain, or assist third parties in obtaining, access to the Platform.<br>3.2 You warrant to us that all the information you provide to ABAKA is true and accurate to the best of your knowledge.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">4. ACCEPTABLE USE RESTRICTIONS</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">You must:</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">not use the Platform in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with this agreement, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, including viruses, or harmful data, any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware into the Platform or any operating system;<br>not infringe our intellectual property rights or those of any third party in relation to your use of the Platform, or cause our infringement of such rights in relation to our operation of the Platform;<br>not transmit any material that is defamatory, offensive or otherwise objectionable in relation to your use of the Platform;<br>not use the Platform in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users; and<br>not collect or harvest any information or data from any Platform or our systems or attempt to decipher any transmissions to or from the servers running the Platform.<br>5. INTELLECTUAL PROPERTY RIGHTS</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">ABAKA is the owner or the licensee of all intellectual property rights in the Platform, and in the material published on it. All such rights are reserved. You acknowledge that you have no rights in or to the Platform other than the right to access and use the Platform in accordance with the terms of this agreement.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">6. DATA</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">We will process any personal data you transmit via the Platform, or otherwise give us access to, only in accordance with the Privacy Policy</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">7. CHANGES TO THIS AGREEMENT</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">We may change this agreement at any time. Any changes we make in the future will be posted on our End User Access Agreement and, where appropriate, notified to you by e-mail or displayed on-screen when you next access the Platform.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">8. DISCLAIMER</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">8.1 Financial Instruments and financial services are subject to various risks. Thus, it may be necessary to consult a professional adviser on financial, tax and/or legal matters. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">Before you decide to invest you should be sure that your immediate financial priorities are met</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">8.2 ABAKA takes all reasonable steps to ensure that any information, data, services, systems, articles, bulletins and other content (“content”) published from time to time on the Platform is accurate and complete and complies with relevant English legislation and regulations as at the date of issue.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">8.3 However, we regret that no liability can be accepted for any errors or omissions. We reserve the right at any time to change the content, presentation, performance, facilities and availability of all or part of the pages of this site at our sole discretion and without prior notice. We reserve the right at any time, and without prior notice, to remove or cease to supply any product or service contained on this website. In the event of any such change or removal we shall not be liable to you in any way whatsoever for such change or removal.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">8.4 Whilst we take all reasonable steps to ensure that the information on the website is accurate and up to date, neither ABAKA nor its suppliers warrant that it is. You acknowledge and agree that neither ABAKA nor its suppliers have any control over the accuracy or completeness of third party data and all liability in respect of the same is expressly excluded to the maximum extent permitted by law.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">9. ABAKA’S LIABILITY</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">9.1 Nothing in this agreement shall limit or exclude our liability for:</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">death or personal injury resulting from our negligence;<br>fraud or fraudulent misrepresentation; and<br>any other liability that cannot be excluded or limited by English law.<br>9.2 You acknowledge that the Platform has not been developed to meet your particular requirements, and that it is therefore your responsibility to ensure that the facilities and functions of the Platform meet your requirements.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">9.3 We shall not be liable whether in tort (including for negligence or breach of statutory duty), contract, misrepresentation, restitution or otherwise for any loss of profits, loss of business, depletion of goodwill and/or similar losses or loss or corruption of data or information, or pure economic loss, or for any special, indirect or consequential loss, costs, damages, charges or expenses however arising under this agreement.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">9.4 Subject to 9.1, we shall not be liable for any loss, damages, costs, claims or expenses whatsoever arising from:</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">the acts, errors or omissions of third parties;<br>the accuracy or completeness of the Platform or content;<br>your failure to ensure the security of your ID and password (in particular but not limited to if you have told anyone your Platform security details, deliberately or negligently failed to keep any of your Platform security details safe);<br>for your breach of any terms of your linked accounts – this includes if you lose protections from any external provider because you have broken their terms by your use of aggregation, and<br>your use of the Platform and content otherwise than in accordance with these terms of use.<br>9.5 Our maximum aggregate liability under or in connection with this agreement (including your use of any Platform) whether in contract, tort (including negligence) or otherwise, shall in all circumstances be limited to £100. This does not apply to the types of loss set out in paragraph 9.1. In addition, if you experience a loss as a direct result of your use of the Platform which we agree is our fault, we will refund you that loss. We will also be liable to you if you experience loss directly because of our fraud.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">9.6 To the extent permitted by law, we exclude all conditions, warranties and representations which may apply to the Platform, whether express or implied.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">10. TERMINATION</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">We may terminate this agreement immediately by written notice to you if you commit a material or persistent breach of this agreement. On termination for any reason all rights granted to you under this agreement shall cease.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">11. OTHER IMPORTANT TERMS</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">11.1 We may transfer our rights and obligations under this agreement to another organisation, but this will not affect your rights or our obligations under this agreement.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">11.2 If we fail to insist that you perform any of your obligations under this agreement, or if we do not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived our rights against you and will not mean that you do not have to comply with those obligations. If we do waive a default by you, we will only do so in writing, and that will not mean that we will automatically waive any later default by you.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">11.3 Each of the paragraphs of this agreement operates separately. If any court or competent authority decides that any of them are unlawful or unenforceable, the remaining paragraphs will remain in full force and effect.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">11.4 Please note that this agreement, its subject matter and its formation, are governed by English law. You and we both agree that the courts of England and Wales will have exclusive jurisdiction.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">12. Freemium Services Agreement</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">This Freemium Services Agreement sets out the terms and conditions which are incorporated into the End User Access Agreement and apply to the Platform.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">By accessing and/or registering to use the Platform, you agree to the Freemium Services Agreement terms and conditions of this agreement which will bind you and be incorporated into the End User Access Agreement and apply to the Platform. If you do not agree to the terms of this agreement, you must not access or use the Platform.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">12.1 TERM &amp; TERMINATION. This Freemium Services Agreement and End User Access Agreement (together the “Agreements”) shall commence on the Effective Date (the date End User clicks to accept the Agreements) and shall continue until ABAKA or End User decides to terminate it. ABAKA may immediately terminate the Agreements upon written notice. End User shall send ABAKA the termination request in order to delete the account and data within 30 days period. If End User attempts to breach Agreements by performing activities abusing ABAKA free services, all suspicious accounts will be deleted immediately.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">12.2 TERMINATION FOR INACTIVITY. ABAKA reserves the right to terminate the Agreements for inactivity, if, for a period exceeding 180 days End User has not logged in to the Platform.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">12.3 FEES &amp; PAYMENT TERMS. The Platform is provided to End User without charge up to 6 months after the Effective Date. Free service is provided without any warranties and can be discontinued or terminated anytime. After the free 6 months period, End User will be charged a fee of £2 per month.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">12.4 SERVICE CHANGES. ABAKA may make upgrades or changes to the Platform without prior notice to End User.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">12.5 CHANGES TO THIS AGREEMENT. We may change this agreement at any time. Any changes we make in the future will be posted on our End User Access Agreement and, where appropriate, notified to you by e-mail or displayed on-screen when you next access the Platform.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">13. Promotional Offers</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">The Promotion Offer Agreement sets out the terms and conditions which are incorporated into the current offering as outlined on the website.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">By accessing and/or registering to use the promotional offerings, you agree to the promotional offer Agreement terms and conditions of this agreement. If you do not agree to the terms of this agreement, you must not access or use the Platform.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">13.1 GIFT CARD. The gift card offering is open to new customers aged 18 or over, excluding ABAKA Holdings Ltd employees, their immediate family, or any person or company associated with the offer. To receive the gift card, you must follow the sign-up requirements as defined by supporting webpage.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">13.2 USAGE. This offer is only available for a limited time only, subject to availability. This offer will be immediately suspended upon the timed threshold being reached.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">13.3 TERM AND TERMINATION. ABAKA Holdings Ltd reserves the right to cancel, amend or withdraw this offer and these terms and conditions without notice. ABAKA Holdings Ltd reserves the absolute right to withhold the offer if we suspect that a sign-up is fraudulent or duplicated for the purpose of multiple offer claims. ABAKA Holdings Ltd reserves the right to withhold the offer for any registrations that we suspect or consider have used improper technical means to sign-up for the purpose of multiple offer claims.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">13.4 TERMS. All decisions of management will be final and binding. There is no entry fee and no purchase necessary to qualify for the offer. No cash alternative to the offer will be provided. The offer is not transferrable. The offer is subject to availability and we reserve the right to substitute any offer with another of equivalent value without giving notice. You will receive your Amazon.co.uk gift card code via SMS or any other applicable communication channel as deemed reasonable by ABAKA Holdings.</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">13.5 By entering the offer you consent to abide by Amazon.co.uk’s terms &amp; conditions. Please see www.amazon.co.uk/gc-legal/ for more information</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">13.6 By entering the offer, individual’s consent to being contacted by ABAKA Holdings for future activity and offers. Your data will not be shared with any third-party organisations and you have the right to cancel at any time by emailing feedback@abaka.me with the subject line “Unsubscribe”</p>



<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">The promoter of the competition is ABAKA Holdings Ltd.</p>
              </div>
        </div>
    </div>
</div>

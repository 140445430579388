import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contactarea',
  templateUrl: './contactarea.component.html',
  styleUrls: ['./contactarea.component.scss']
})
export class ContactareaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partnersform',
  templateUrl: './partnersform.component.html',
  styleUrls: ['./partnersform.component.scss']
})
export class PartnersformComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="internalPageTp1">
    <div class="HdTpPg1">
        <div class="container">
            What should i know
        </div>
    </div>
    <div class="cont-box-1">
        <div class="container">
            ijas
        </div>
    </div>
</div>
<div class="TechAlWrp text-center">
    <div class="container">
        <div class="HdStyle1 wow fadeInLeft text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
            Tech <span>Alliances</span>
        </div>
        <div class="TxtStyle1 wow fadeInLeft text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
            We’ve built Tech Alliances with industry leaders. We are ensuring our clients<br/>
             have access to the most innovative, secure and reliable technologies in cloud<br/>
              computing, data security, data privacy, KYC and core back-end platforms.
        </div>
        <div class="techLogos">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <img class=" wow fadeInRight"  data-wow-duration="1s" data-wow-delay="0.5s" src="../../../../assets/images/tech1.jpg" alt="">
                    </div>
                    <div class="col-md-4">
                        <img class=" wow fadeInRight"  data-wow-duration="1s" data-wow-delay="0.5s" src="../../../../assets/images/tech2.jpg" alt="">
                    </div>
                    <div class="col-md-4">
                        <img class=" wow fadeInRight"  data-wow-duration="1s" data-wow-delay="0.5s" src="../../../../assets/images/tech3.jpg" alt="">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <img class=" wow fadeInRight"  data-wow-duration="1s" data-wow-delay="0.5s" src="../../../../assets/images/tech4.jpg" alt="">
                    </div>
                    <div class="col-md-4">
                        <img class=" wow fadeInRight"  data-wow-duration="1s" data-wow-delay="0.5s" src="../../../../assets/images/tech5.jpg" alt="">
                    </div>
                    <div class="col-md-4">
                        <img class=" wow fadeInRight"  data-wow-duration="1s" data-wow-delay="0.5s" src="../../../../assets/images/tech6.jpg" alt="">
                    </div>
                </div>
            </div>
        </div> 
    </div>
</div>
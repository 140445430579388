import { Component, OnInit } from '@angular/core';
import { GetNewsService } from '../../../services/get-news.service';
import { Blogs } from '../../../models/blogs';
import { ActivatedRoute } from '@angular/router';

declare function loadNewsCarousel(): any;
@Component({
  selector: 'app-ournewsblock',
  templateUrl: './ournewsblock.component.html',
  styleUrls: ['./ournewsblock.component.scss']
})
export class OurnewsblockComponent implements OnInit {

  blogs: Blogs; 
  pageCount; 
  pageNo;
  totalPages: Number;
  currentPage;

  constructor(
    private getBlogs:GetNewsService, 
    private activatedRoute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe( params => {
      this.pageNo= params['pageNo'];
    });
    
    this.getBlogs.viewBlogs().subscribe(data =>{
      for (var i in data['data']){
        
        let str1 = data['data'][i].title;
        let len1 = str1.length;
        let t1 = str1.substring(0, 45);
        if(len1 > 45 ) t1 = t1+"...";
        data['data'][i].title = t1;
        
        let str2 = this.extractContent(data['data'][i].content);
        let len2 = str2.length;
        let t2 = str2.substring(0, 80);
        if(len2 > 45 ) t2 = t2+"...";
        data['data'][i].content = t2;
        
      }
       this.blogs = data['data'];
       this.totalPages = data['total_pages'];
       this.pageCount = new Array(this.totalPages);
       this.currentPage = 1;
       loadNewsCarousel();
    });

    if(this.pageNo > 0){
      this.getBlogs.viewMoreBlogs(this.pageNo).subscribe(data =>{
          this.blogs = data['data'];
          this.currentPage = this.pageNo;
      });
    }
  }
  
  extractContent(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetBlogDetailsService } from '../../services/get-blog-details.service';
import { BlogDetails } from '../../models/blog-details';
@Component({
  selector: 'app-blogdetailpage',
  templateUrl: './blogdetailpage.component.html',
  styleUrls: ['./blogdetailpage.component.scss']
})
export class BlogdetailpageComponent implements OnInit {

  blogDetails: BlogDetails;
  blogSlug: string;

  constructor(
    private getBlogDetails:GetBlogDetailsService,
    private activatedRoute:ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe( params=> {
        this.blogSlug = params['slug'];

        this.getBlogDetails.viewBlogDetails(this.blogSlug).subscribe(data =>{
          this.blogDetails = data['data'][0];            
      });          
    });      

  }

}

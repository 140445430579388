import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  deviceInfo = null;
  isMobile=null;
  isTablet=null;
  isDesktopDevice=null;
  constructor(private deviceService: DeviceDetectorService , private router: Router) { 
      this.isMobile = this.deviceService.isMobile();
      this.isTablet = this.deviceService.isTablet();
      this.isDesktopDevice = this.deviceService.isDesktop();
  }

  ngOnInit(): void {
    
  }
  @ViewChild("clients") MyProp: ElementRef;
  clients= function () {
      this.router.navigateByUrl('/');
      this.MyProp.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  toScroll(id){
    document.getElementById(id).scrollIntoView({behavior:'smooth'});
  }

  /* toScroll(id){
      var element = document.getElementById(id);
      var headerOffset = 120;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      });
  } */
   
}

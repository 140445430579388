import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
@Component({
  selector: 'app-successmetrics',
  templateUrl: './successmetrics.component.html',
  styleUrls: ['./successmetrics.component.scss']
})
export class SuccessmetricsComponent implements OnInit {
  public lottieConfig: Object;
  private anim: any;
  private animationSpeed: number = 1;
  constructor() { 
    this.lottieConfig = {
      path: '/assets/webcanvas/graph.json',
      renderer: 'svg',
      autoplay: true,
      loop: true
    };
  }
  handleAnimation(anim: any) {
    this.anim = 100;
}

  ngOnInit(): void {
  }
  // options: AnimationOptions = {
  //   renderer: 'svg',
  //   path: '/assets/webcanvas/circle moving.json',
  // };
 

  // animationCreated(animationItem: AnimationItem): void {
  //   console.log(animationItem);
  // }
}

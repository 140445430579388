<div class="BecomeParBx">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="becPartBox">
                    <div class="becPartBoxT1 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Become</div>
                    <div class="becPartBoxT2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">A <b>Partner</b></div>
                    <div class="becPartBoxT3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                        If you want to revolutionise the <br/>way the industry financially plans for its<br/> customers, let’s talk.
                    </div>
                    <div  class="becPartBoxT4 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                        <span class="scrolltocontact">Become A Partner</span>
                    </div>
                </div>
            </div>
            <div class="col-md-6">&nbsp;</div>
        </div>
    </div>
</div>
<div class="LeadBoxTp1">
    <div class="container">
        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-3 wow fadeInLeft"  data-wow-duration="1s" data-wow-delay="0.5s">
                <div class="LdrBoTp1">
                    <img class="img-fluid" src="../../../../assets/images/leader1.jpg" alt="">
                    <div class="ldrtxt1">Fahd Rachidy</div>
                    <div class="ldrtxt2">Founder and CEO</div>
                    <div class="ldrtxt3">Fahd is a serial entrepreneur passionate about helping the industry create secure financial futures for everyone and re-inventing the way the financial industry works using Artificial Intelligence. Fahd was previously co-founder of Scientificbeta.com, the leading index digital platform recently acquired by the Singapore Stock Exchange (SGX) for $205m..</div>
                </div>
            </div>
            <div class="col-md-3 wow fadeInRight"  data-wow-duration="1s" data-wow-delay="0.5s">
                <div class="LdrBoTp1">
                    <img class="img-fluid" src="../../../../assets/images/leader2.jpg" alt="">
                    <div class="ldrtxt1">Benoit Launay</div>
                    <div class="ldrtxt2">Co-Founder and CTO</div>
                    <div class="ldrtxt3">Former Head of Business Intelligence, Senior Product Developer & Solution Architect at Microsoft for over 13 years, expert in Machine Learning, cloud-based platforms and data management. Benoit started in voice recognition at Bell Labs New-York in 2001.</div>
                </div>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>
    </div>
</div>
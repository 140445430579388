import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-heroslider1',
  templateUrl: './heroslider1.component.html',
  styleUrls: ['./heroslider1.component.scss']
})
export class Heroslider1Component implements OnInit {
  deviceInfo = null;
  isMobile=null;
  isTablet=null;
  isDesktopDevice=null;

  constructor(private deviceService: DeviceDetectorService) { 
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  ngOnInit(): void {
  }
  options: AnimationOptions = {
    path: '/assets/webcanvas/home-page-animation-new.json',
  };
  options1: AnimationOptions = {
    path: '/assets/webcanvas/circle moving.json',
  };
  options2: AnimationOptions = {
    path: '/assets/webcanvas/artificial-finance.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

}

<div class="DescBoxTp1 text-center">
    <div class="container">
        <div class="HdStyle1 wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
            A Message From <span> Our Founders</span>
        </div>
        <div class="TxtStyle1 wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
            “The arrival of big data, AI, &amp; machine learning is now starting to revolutionize<br/>
                traditional marketing and the customer journey management processes. The gathering,<br/>
                analysis, and application of large amounts of behavioral and demographic data are<br/>

                providing companies with a richer understanding of their existing and target customers.<br/>
                Next Best Action significantly accelerates how banks can access and mine data to deliver<br/>
                hyper-personalized digital experiences to their customers at scale.”<br/>
        </div>
        <!-- <div class="TxtStyle1 wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
            Digital technology has revolutionised the world around us. Screens and mobile are everywhere.<br>
            They have transformed the working world, from electric cars to online grocery shopping,<br>
            businesses have changed, adapted and progressed. Business is no longer analogue; it is digital.
        </div>
        <div class="TxtStyle1 wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
            And AI is pushing that digital transformation further, bringing with it bigger, better and<br>
            pioneering opportunities. <b>ABAKA</b> is harnessing AI to revolutionise how organisations<br>
            financially plan for their clients.
        </div> -->
        <div class="TxtStyle1 wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
            Join us. It’s time everyone benefited from
            <a href="#">Artificial Financial Intelligence<sup>TM</sup>.</a>
        </div>
        <div class="HdStyle1 MargTpadjust wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
            The <span>Leadership</span>
        </div>
        <div class="text-center">
            <img class="img-fluid" src="../../../../assets/images/about-wave-style1.png" alt="">
        </div>
    </div>
   
</div>
<app-leadership></app-leadership>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-techalliances',
  templateUrl: './techalliances.component.html',
  styleUrls: ['./techalliances.component.scss']
})
export class TechalliancesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-testimonial1',
  templateUrl: './testimonial1.component.html',
  styleUrls: ['./testimonial1.component.scss']
})
export class Testimonial1Component implements OnInit {

  deviceInfo = null;
  isMobile=null;
  isTablet=null;
  isDesktopDevice=null;

  constructor(private deviceService: DeviceDetectorService) { 
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  ngOnInit(): void {
  }
  options: AnimationOptions = {
    path: '/assets/webcanvas/moving-grid.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

}

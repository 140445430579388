<div class="internalPageTp1">
    <img src="../../../assets/images/aboutus-banner.jpg">
    <div class="HdTpPg1">
        <div class="container">
            {{blogDetails?.title}}
        </div>
    </div>
    <div class="cont-box-1">
        <div class="container">
            <div class="content-text">
                
<p  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">{{blogDetails?.date}}</p>


<div [innerHTML]="blogDetails?.content"></div>

              </div>
        </div>
    </div>
</div>

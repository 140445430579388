<div class="Sec-Tp-1">
    <div class="container">
        <div class="HdStyle1 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
            Our <span>Partners</span>
        </div>
        <div class="TxtStyle1 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
            ABAKA has a proven track record of delivering enterprise-level SaaS <br/>
            solutions to some of the world's leading financial institutions.
        </div>
        <div class="partnerslist">
            <div *ngIf="isDesktopDevice">
                <div class="row BrdBtm">
                    <div class="col-md-3 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p1.jpg" alt="">
                    </div>
                    <div class="col-md-3 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p2.jpg" alt="">
                    </div>
                    <div class="col-md-3 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/Partners Logo.png" alt="">
                    </div>
                    <div class="col-md-3 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p4.jpg" alt="">
                    </div>
                    <div class="col-md-3 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/Partners Logo (1).png" alt="">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p6.jpg" alt="">
                    </div>
                    <div class="col-md-3 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p7.jpg" alt="">
                    </div>
                    <div class="col-md-3 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p8.jpg" alt="">
                    </div>
                    <div class="col-md-3 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p9.jpg" alt="">
                    </div>
                    <div class="col-md-3 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p10.jpg" alt="">
                    </div>
                </div>
            </div>
            <div *ngIf="isMobile||isTablet">
                <div class="row">
                    <div class="col-md-3 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p1.jpg" alt="">
                    </div>
                    <div class="col-md-3 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p2.jpg" alt="">
                    </div>
                    <div class="col-md-3 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/Partners Logo.png" alt="">
                    </div>
                    <div class="col-md-3 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p4.jpg" alt="">
                    </div>
                    <div class="col-md-3 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/Partners Logo (1).png" alt="">
                    </div>
                    <div class="col-md-3 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p6.jpg" alt="">
                    </div>
                    <div class="col-md-3 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p7.jpg" alt="">
                    </div>
                    <div class="col-md-3 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p8.jpg" alt="">
                    </div>
                    <div class="col-md-3 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p9.jpg" alt="">
                    </div>
                    <div class="col-md-3 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p10.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
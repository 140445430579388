<div *ngIf="showCookieConsent" class="CookieBox">
    <div class="container">
        <div class="row">
            <div class="col-md-10 text-center">
                This website uses cookies to improve your experience. We’ll assume you’re ok with this, but you can opt-out if you wish.<br/> <a routerLink="/cookis-policy">Cookies Settings</a>
            </div>
            <div class="col-md-2 text-center">
                <button (click)="acceptCookie();" class="btnc">Accept</button>
            </div>
        </div>
    </div>
</div>
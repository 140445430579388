<div class="homeVideoTp1 wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
    <!-- <a href="https://www.youtube.com/watch?v=eK6SzU776KY" class="PlayBtn video-btn popup-youtube">
        <div class="hover15">
            <figure>
                <img src="../../../assets/images/video2.jpg" alt="">
            </figure>
        </div>
    </a> -->
    <video loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" style="width: 100%;">
        <source src="../../../assets/videos/abaka-home.mp4" type="video/mp4">
    </video>
</div>
<div class="Abk-cnt-wrp">
    <div class="content-box">
      <div class="MainBannerT1">
        <app-mainbannerresources></app-mainbannerresources>
      </div>
      <div id="blogs" class="resoucercesBlock">
        <app-ourblogsblock></app-ourblogsblock>
      </div>
      <div id="news" class="NewsBlock">
        <app-ournewsblock></app-ournewsblock>
      </div>
      <div id="events" class="eventsBlock">
        <app-eventsblock></app-eventsblock>
      </div>
      <div class="getintouchForm">
        <!-- <app-eventsform></app-eventsform> -->
        <app-getintouch></app-getintouch>
      </div>
    </div>
</div>
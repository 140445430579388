<app-cookietab></app-cookietab>
<footer class="abk-footer">
  
    <div class="go-top active"><i class="bx bx-up-arrow-alt"></i></div>
    <div class="ftrcnt">
        <div class="container">
            <div class="row">
                <div class="col-md-2 text-center">
                    <a routerLink="/">
                        <img src="../../assets/images/abaka-footer-logo.png" alt="abaka.me"  class=" wow fadeInRight"  data-wow-duration="1s" data-wow-delay="0.5s">
                    </a>
                </div>
                <div class="col-md-4">
                    <div class="LinkBoxTp1 wow fadeInRight"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <div class="LinkBoxTp1Txt1">Company information</div>
                        <div class="LinkBoxTp1Txt2">ABAKA is registered trade mark of ABAKA Holding LTD</div>
                        <div class="LinkBoxTp1Txt3">ABAKA Holdings Ltd is registered in England and Wales, Company no 09943429, with a registered office ABAKA Holdings Ltd, 111 Buckingham Palace Road, London SW1W 0SR, United Kingdom.</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="footerLinks wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <a ><div class="LinkBoxTp1Txt1">What you should know</div></a>
                        <a routerLink="/risk-warnings"><div class="LinkBoxTp1Txt1">Risk warnings</div></a>
                        <a routerLink="/privacy-policy"><div class="LinkBoxTp1Txt1">Privacy policy</div></a>
                        <a routerLink="/cookis-policy"><div class="LinkBoxTp1Txt1">Cookies policy</div></a>
                        <a routerLink="/terms-and-condition"><div class="LinkBoxTp1Txt1">Terms & conditions</div></a>
                        <a routerLink="/faq"><div class="LinkBoxTp1Txt1">FAQs</div></a>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="LinkBoxTp1 wow fadeInLeft"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <div class="LinkBoxTp1Txt1">Company Information</div>
                        <div class="socialFooter">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/AbakaApp/" title="facebook" target="_blank"><img src="assets/images/facebook.png" alt=""></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/My_Abaka" title="twitter" target="_blank"><img src="assets/images/twitter.png" alt=""></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/my-abaka/" title="linkedin" target="_blank"><img src="assets/images/linkedin.png" alt=""></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</footer>
<div class="FooterBottom">
    <div class="container">
        <div class="row">
            <div class="col-md-2 align-items-center d-flex">
                <div class="FooterMTxt wow fadeInRight"  data-wow-duration="1s" data-wow-delay="0.5s">Where to find us</div>
            </div>
            
            <div class="col-md-3 align-items-center d-flex">
                <div class="TabIconWrp wow fadeInLeft"  data-wow-duration="1s" data-wow-delay="0.5s">
                    <div [ngClass]="{'active': step === 1}"  (click)="step=1" class="tabIcon1">United Kingdom</div>
                    <!-- <div [ngClass]="{'active': step === 2}"  (click)="step=2" class="tabIcon1">Singapore</div> -->
                    <div [ngClass]="{'active': step === 3}"  (click)="step=3" class="tabIcon1">France</div>
                    <div [ngClass]="{'active': step === 4}"  (click)="step=4" class="tabIcon1">United Arab Emirates</div>
                </div>
            </div>
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-6">
                <div *ngIf="step == 1" class="mapImage wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
                    <a href="https://goo.gl/maps/zUkQyTFUDo3C93YW7" target="_blank">
                        <img src="../../assets/images/United Kingdom-min.jpg" alt="">
                    </a>
                </div>
                <div *ngIf="step == 2" class="mapImage wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
                    <a href="https://goo.gl/maps/p47ccjxSSRk2V42c8" target="_blank">
                        <img src="../../assets/images/Singapore-min.jpg" alt="">
                    </a>
                </div>
                <div *ngIf="step == 3" class="mapImage wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
                    <a href="https://goo.gl/maps/CcsGQ4ZGq2zeRLY68" target="_blank">
                    <img src="../../assets/images/France-min.jpg" alt="">
                </a>
                </div>
                <div *ngIf="step == 4" class="mapImage wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
                    <a href="https://g.page/Standard-Chartered-Tower-2766" target="_blank">
                    <img src="../../assets/images/UAE-min.jpg" alt="">
                </a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="copyRight">
    <div class="container">
        © ABAKA holdings Ltd
    </div>
</div>
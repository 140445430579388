<div class="solutionGrdboxes">
    <div class="solutionGridBox wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
        <div class="container">
            <div class="row d-flex align-items-center justify-content-between">
                <div class="col-md-5 d-flex  align-items-center justify-content-start">
                    <div data-aos="fade-up">
                        <div class="txttest1 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Goal Based Saving &</div>
                        <div class="txttest2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Retirement Planning</div>
                        <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                            On-going engagement is an essential<br/> part of customer retention.<br/>
                            Intelligent nudges provides that.<br/>
                            We employ machine learning across big<br/> data to deliver hyper-personalisation<br/> engagement.
                         </div>
                        <div class="txttest4 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Contact us</div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="canvasBoxTp1">
                        <ng-lottie [options]="options4" (animationCreated)="animationCreated($event)"></ng-lottie>
                    </div>
                </div>
            </div>
            <div class="BottomTxt1">
                <div class="">
                    <div data-aos="fade-up">
                        <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                            On-going engagement is an essential part of customer retention.
                            Intelligent nudges provides that.
                            We employ machine learning across big data to deliver hyper-personalisation engagement.
                            On-going engagement is an essential part of customer retention.
                            Intelligent nudges provides that.
                            We employ machine learning across big data to deliver hyper-personalisation engagement.
                            On-going engagement is an essential part of customer retention.
                            Intelligent nudges provides that.
                            We employ machine learning across big data to deliver hyper-personalisation engagement.
                            On-going engagement is an essential part of customer retention.
                            Intelligent nudges provides that.
                            We employ machine learning across big data to deliver hyper-personalisation engagement.
                         </div>
                    </div>
                </div>
            </div>
        </div>
      
    </div>
    <div class="SectionBox enquiryForm">
        <app-getintouch></app-getintouch>
    </div>
</div>
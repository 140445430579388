<div class="Abk-cnt-wrp" data-aos="fade-up">
    <div class="content-box">
         <div class="mainBanner">
           <app-mainbanner></app-mainbanner>
         </div>
         <div class="grdBox">
           <app-solutiongrids></app-solutiongrids>
         </div>
         <div class="ContactFrm">
           <!-- <app-solutiongetintouch></app-solutiongetintouch> -->
           <app-getintouch></app-getintouch>
         </div>
    </div>
</div>
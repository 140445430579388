<div class="InvestorsBlock">
    <div class="container">
        <div class="HdStyle1 text-center wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
            <span>Investors</span>
        </div>
        <div class="subTtlTp1 text-center wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">We are proud to have a roster of backers which includes some of the<br/> biggest names in the financial service industry.</div>
        <div class="Investorslogo">
            <div class="row d-flex justify-content-center align-items-center">
                <div class="col-md-3 wow fadeInLeft"  data-wow-duration="1s" data-wow-delay="0.5s">
                    <div class="InvestorslogoBx wow fadeInLeft"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <a href="http://www.lingfengcap.com/index/en" target="_blank"><img src="../../../../assets/images/investor1.jpg" alt=""></a>
                        
                    </div>
                </div>
                <div class="col-md-3 wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
                    <div class="InvestorslogoBx wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <a href="https://www.downingventures.com/" target="_blank"><img src="../../../../assets/images/Inverstors Logo.png" alt=""></a>
                        
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-center align-items-center">
                
                <div class="col-md-3 wow fadeInRight"  data-wow-duration="1s" data-wow-delay="0.5s">
                    <div class="InvestorslogoBx">
                        <a href="https://aceandcompany.com/" target="_blank"><img src="../../../../assets/images/investor3.jpg" alt=""></a>
                        
                    </div>
                </div>
                <div class="col-md-3 wow fadeInRight"  data-wow-duration="1s" data-wow-delay="0.5s">
                    <div class="InvestorslogoBx">
                        <a href="https://www.anthemis.com/" target="_blank"><img src="../../../../assets/images/logo2.png" alt=""></a>
                        
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div>

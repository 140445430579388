<div class="EventBoxWrap">
    <div class="container">
        <div class="BnrTp1">
            <img class="img-fluid wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s" src="../../../../assets/images/eventsbanner.jpg" alt="">
        </div>
        <div class="EventsItemWrp">
            <div class="row">
                <div class="col-md-8">
                    <div class="EventsItemText1 wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">Events</div>
                    <div class="EventsItemText2 wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">Our Last Webinar – Notify to Inform,<br/> Nudge to Engage</div>
                    <div class="EventsItemText3 wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">ABAKA held the latest in our series of Webinars on the 16th July<br/>
                         looking at how Nudge Theory can be applied to drive true<br/> engagement with clients.</div>
                    <div class="EventsItemText3 wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">
                        Hosted by our Director of Nudges and Insight, Andrew Lea, <br/>
                        we explored how the theory can be put in place in a financial<br/>
                         services context to drive engagement and actions.</div>
                    <div class="EventsItemText3 wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">For a copy of the recording, please complete the<br/> contact us form below.</div>
                </div>
                <div class="col-md-4 d-flex align-items-center">
                    <div class="EventsItemText4 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                        <span>We will shortly be announcing
                            the next webinar in our series.</span>
                            To be kept up to date, please complete the form below.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetInTouch } from '../models/get-in-touch';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SendDataService {

  constructor(private http:HttpClient) { }

  getInTouch(getInTouchData): Observable<GetInTouch>{    
    const getInTouchUrl = environment.baseUrl+'get_in_touch/';
    return this.http.post<GetInTouch>(getInTouchUrl, getInTouchData);
  }
}

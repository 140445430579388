<div class="preloader123">
    <div class="text-center">
        <div class="PrTxt1">
            Hello I’m ABAKA.
        </div>
        <div class="PrTxt2"> 
            An AI that’s transforming the digital finance landscape.
        </div>
        <div class="text-center">
            <!-- <a routerLink="/solutions" class="NavLinkTp1">Start Exploring</a> -->
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-ourpartnersblock',
  templateUrl: './ourpartnersblock.component.html',
  styleUrls: ['./ourpartnersblock.component.scss']
})
export class OurpartnersblockComponent implements OnInit {

  deviceInfo = null;
  isMobile=null;
  isTablet=null;
  isDesktopDevice=null;

  constructor(private deviceService: DeviceDetectorService) { 
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }


  ngOnInit(): void {
  }

}

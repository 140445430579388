import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { ResourcesComponent } from './resources/resources.component';
import { PartnersComponent } from './partners/partners.component';

import { WhatshouldiknowComponent } from './components/whatshouldiknow/whatshouldiknow.component';
import { RiskwarningsComponent } from './components/riskwarnings/riskwarnings.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { ComponentscookispolicyComponent } from './componentscookispolicy/componentscookispolicy.component';
import { CookiespolicyComponent } from './components/cookiespolicy/cookiespolicy.component';
import { TermsandconditionComponent } from './components/termsandcondition/termsandcondition.component';
import { FaqComponent } from './components/faq/faq.component';
import { BlogdetailpageComponent } from './components/blogdetailpage/blogdetailpage.component';
import { WelcomemessageComponent } from './components/common/welcomemessage/welcomemessage.component';
const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'home', component: HomeComponent},
    {path: 'solutions', component: SolutionsComponent},
    {path: 'aboutus', component: AboutusComponent},
    {path: 'resources', component: ResourcesComponent},
    {path: 'partners', component: PartnersComponent},

    {path: 'what-you-should-know', component: WhatshouldiknowComponent},
    {path: 'risk-warnings', component: RiskwarningsComponent},
    {path: 'privacy-policy', component: PrivacypolicyComponent},
    {path: 'cookis-policy', component: CookiespolicyComponent},
    {path: 'terms-and-condition', component: TermsandconditionComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'blogdetails/:slug', component: BlogdetailpageComponent},
    {path: 'next-best-action', component: ResourcesComponent},
    // Here add new pages component

    // {path: '**', component: ErrorPageComponent} 
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BlogDetails } from '../models/blog-details';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetBlogDetailsService {

  constructor(private http:HttpClient) { }

  viewBlogDetails(slug): Observable<BlogDetails>{
    const blogDetailsUrl = environment.baseUrl+'blog_details/'+slug;
    return this.http.get<BlogDetails>(blogDetailsUrl);
  }

}

<div class="abtLcWrp">
    <div class="container">
        <div class="HdStyle1 text-center wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
            <span>Locations</span>
        </div>
        <div class="subTtlTp1 text-center wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
            ABAKA is head quartered in central London, with offices<br/> across the world. We are global and we are local.
        </div>
        <div class="LocationBxAb wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
            <div *ngIf="step == 1" class="mapImage wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
                <a href="https://goo.gl/maps/zUkQyTFUDo3C93YW7" target="_blank">
                    <img src="../../assets/images/United Kingdom-min.jpg" alt="">
                </a>
            </div>
            <div *ngIf="step == 2" class="mapImage wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
                <a href="https://goo.gl/maps/p47ccjxSSRk2V42c8" target="_blank">
                    <img src="../../assets/images/Singapore-min.jpg" alt="">
                </a>
            </div>
            <div *ngIf="step == 3" class="mapImage wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
                <a href="https://goo.gl/maps/CcsGQ4ZGq2zeRLY68" target="_blank">
                <img src="../../assets/images/France-min.jpg" alt="">
            </a>
            </div>
            <div *ngIf="step == 4" class="mapImage wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
                <a href="https://g.page/Standard-Chartered-Tower-2766" target="_blank">
                <img src="../../assets/images/UAE-min.jpg" alt="">
            </a>
            </div>
        </div>
        <div class="TbBtn">
            <div class="row d-flex align-items-center justify-content-center">
                <div class="col-md-4">
                    <div class="tabIcon1" [ngClass]="{'active': step === 1}"  (click)="step=1">United Kingdom</div>
                </div>
                <!-- <div class="col-md-3">
                    <div class="tabIcon1" [ngClass]="{'active': step === 2}"  (click)="step=2">Singapore</div>
                </div> -->
                <div class="col-md-4">
                    <div class="tabIcon1" [ngClass]="{'active': step === 3}"  (click)="step=3">France</div>
                </div>
                <div class="col-md-4">
                    <div class="tabIcon1" [ngClass]="{'active': step === 4}"  (click)="step=4">United Arab Emirates</div>
                </div>
            </div>
        </div>
    </div> 
</div>
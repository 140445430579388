<div class="OurPrtnrWrp">
    <div class="container">
        <div class=" wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
            <div class="owl-carousel owl-theme partneshead">
                <div class="item">
                    <div class="orPtHd1">Our <span>Partners</span></div>
                </div>
                <div class="item">
                    <div class="orPtHd1">Our <span>Partners</span></div>
                </div>
                <div class="item">
                    <div class="orPtHd1">Our <span>Partners</span></div>
                </div>
                <div class="item">
                    <div class="orPtHd1">Our <span>Partners</span></div>
                </div>
            </div>
        </div>
        <div  class="orPtHd2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
            <span class="wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                ABAKA has a proven track record of delivering <br/>
                enterprise-level SaaS solutions to some of the 
                <br/>world's leading financial institutions.
            </span>
        </div>
        <div class="partnrSld wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
            <div class="owl-carousel owl-theme partners">
                <div class="item">
                   <div class="ParnerLogoWrp">
                    <div class="ParnerLogo wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p1.jpg" alt="">
                    </div>
                   </div>
                </div>
                <div class="item">
                    <div class="ParnerLogoWrp">
                     <div class="ParnerLogo wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p2.jpg" alt="">
                     </div>
                    </div>
                 </div>
                 <div class="item">
                    <div class="ParnerLogoWrp">
                     <div class="ParnerLogo wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/Partners Logo.png" alt="">
                     </div>
                    </div>
                 </div>
                 <div class="item">
                    <div class="ParnerLogoWrp">
                     <div class="ParnerLogo wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p4.jpg" alt="">
                     </div>
                    </div>
                 </div>
                 <div class="item">
                    <div class="ParnerLogoWrp">
                     <div class="ParnerLogo wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/Partners Logo (1).png" alt="">
                     </div>
                    </div>
                 </div>
                 <div class="item">
                    <div class="ParnerLogoWrp">
                     <div class="ParnerLogo wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p6.jpg" alt="">
                     </div>
                    </div>
                 </div>
                 <div class="item">
                     <div class="ParnerLogoWrp">
                      <div class="ParnerLogo wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p7.jpg" alt="">
                      </div>
                     </div>
                  </div>
                  <div class="item">
                     <div class="ParnerLogoWrp">
                      <div class="ParnerLogo wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p8.jpg" alt="">
                      </div>
                     </div>
                  </div>
                  <div class="item">
                     <div class="ParnerLogoWrp">
                      <div class="ParnerLogo wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p9.jpg" alt="">
                      </div>
                     </div>
                  </div>
                  <div class="item">
                     <div class="ParnerLogoWrp">
                      <div class="ParnerLogo wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/p10.jpg" alt="">
                      </div>
                     </div>
                  </div>
            </div>
        </div>
    </div>
</div>
<div class="ResoSldrTp1">
    <div class="solutionMainBanner">
        <div class="SlideTp1 pt-0 pb-0">
            <div class="Vd0BoxTp1">
                <video loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" style="width: 100%;">
                    <source src="../../../assets/videos/ABAKA-Resources Video.MOV.mp4" type="video/mp4">
                </video>
                <div class="FxdBlk">
                    <div class="container">
                        <!-- <a href="https://www.youtube.com/watch?v=eK6SzU776KY" class="PlayBtn video-btn popup-youtube"><img src="assets/images/playbutton.png" alt=""></a> -->
                        <div class="row d-flex align-items-center justify-content-between">
                            <div class="col-md-6 d-flex  align-items-center justify-content-center">
                                <div class="TestMonialWrp">
                                    <div class="row">
                                        <div class="col-md-2 d-flex align-items-baseline">
                                            <img class="wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s"
                                                src="../../../../assets/images/testleft.png" alt="">
                                        </div>
                                        <div class="col-md-8">
                                            <div class="TestMnTp1">
                                                <div class="ImgTpr1 wow fadeInRight" data-wow-duration="1s"
                                                    data-wow-delay="0.5s">
                                                    <img src="../../../../assets/images/logo123.png" alt="">
                                                </div>
                                                <div class="TestMnTp1Txt1 wow fadeInRight" data-wow-duration="1s"
                                                    data-wow-delay="0.5s">“In my view ABAKA has cracked the
                                                    go-to-market challenge.<br/> Focusing on savings and
                                                    retirement, the proposition is very compelling.”</div>
                                                <div class="TestMnTp1Txt2 wow fadeInRight" data-wow-duration="1s"
                                                    data-wow-delay="0.5s">-HSBC Global Head of Digital</div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 d-flex align-items-end d-none">
                                            <img class="wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s"
                                                src="../../../../assets/images/testright.png" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Blogs } from '../models/blogs';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetNewsService {

  constructor(private http:HttpClient) { }

  viewBlogs(): Observable<Blogs>{    
    const blogsUrl = environment.baseUrl+'get_news/';
    return this.http.get<Blogs>(blogsUrl);
  }

  viewMoreBlogs(pageNo): Observable<Blogs>{    
    const blogsUrl = environment.baseUrl+'get_news/'+pageNo;
    return this.http.get<Blogs>(blogsUrl);
  }


}

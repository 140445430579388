<div *ngIf="isDesktopDevice">
<div class="SlideTp1 autoHeightSlider">
    <div class="owl-carousel owl-theme home-slideskl">
        <div class="item">
            <div class="row d-flex align-items-center justify-content-between">
                <div class="col-md-5 d-flex  align-items-center ">
                    <div data-aos="fade-up">
                        <div class="txttest1 wow fadeInRight SnglLine" data-wow-duration="1s" data-wow-delay="0.5s">Next Best <span class="txttest2 ml-1">Action</span></div>
                        <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                            ABAKA’s AI-recommendation engine predicts what Next Best Action will resonate and activate a customer response, and deliver it to the right people, at the right time and through the right channel with our curated content feed platform, personalized emails, in-app or push notifications.
                         </div>
                        <a routerLink="/solutions">
                            <div class="txttest4 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Read More</div>
                        </a>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="canvasBoxTp1">
                        <ng-lottie [options]="options1" (animationCreated)="animationCreated($event)"></ng-lottie>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="item">
            <div class="row d-flex align-items-center justify-content-between">
                <div class="col-md-5 d-flex  align-items-center ">
                    <div data-aos="fade-up">
                        <div class="txttest1 wow fadeInRight SnglLine" data-wow-duration="1s" data-wow-delay="0.5s">Data <span class="txttest2 ml-1">Ecosystem</span></div>
    
                        <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                            Bring all your data in one place. Augment your dataset for enhanced customer insights.
                         </div>
                        <a routerLink="/solutions">
                            <div class="txttest4 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Read More</div>
                        </a>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="canvasBoxTp1">
                        <ng-lottie [options]="options2" (animationCreated)="animationCreated($event)"></ng-lottie>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="item">
            <div class="row d-flex align-items-center justify-content-between">
                <div class="col-md-5 d-flex  align-items-center ">
                    <div data-aos="fade-up">
                        <div class="txttest1 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Templated Recommendations </div>
                        <div class="txttest2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">& Digital Experiences</div>
                        <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                            Library of 5000+ curated templates supporting omnichannel personalized engagement.
                         </div>
                        <a routerLink="/solutions">
                            <div class="txttest4 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Read More</div>
                        </a>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="canvasBoxTp1">
                        <ng-lottie [options]="options3" (animationCreated)="animationCreated($event)"></ng-lottie>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="item">
            <div class="row d-flex align-items-center justify-content-between">
                <div class="col-md-5 d-flex  align-items-center ">
                    <div data-aos="fade-up">
                        <div class="txttest1 wow fadeInRight SnglLine" data-wow-duration="1s" data-wow-delay="0.5s">Conversational <span class="txttest2 ml-1">AI</span> </div>
                        
                        <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                            NLP models combine user behavioral & financial data to provide personalized guidance at scale.
                         </div>
                         <a routerLink="/solutions">
                            <div class="txttest4 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Read More</div>
                        </a>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="canvasBoxTp1">
                        <ng-lottie [options]="options5" (animationCreated)="animationCreated($event)"></ng-lottie>
                    </div>
                    
                </div>
            </div>
        </div>
       
        <div class="item">
            <div class="row d-flex align-items-center justify-content-between">
                <div class="col-md-7 d-flex  align-items-center ">
                    <div data-aos="fade-up">
                        <div class="txttest1 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Goal Based Saving &</div>
                        <div class="txttest2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Retirement Planning</div>
                        <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                            Customer engagement through data driven<br/> goal setting and retirement planning.
                         </div>
                        <a routerLink="/solutions">
                            <div class="txttest4 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Read More</div>
                        </a>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="canvasBoxTp1">
                        <ng-lottie [options]="options4" (animationCreated)="animationCreated($event)"></ng-lottie>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<div *ngIf="isMobile||isTablet" class="testmansory mobile">
    <div class="SlideTp1 autoHeightSlider">
        <div class="text-center">
            <div class="item">
                <div class="row d-flex align-items-center justify-content-between">
                    <div class="col-md-5 d-flex  align-items-center ">
                        <div data-aos="fade-up">
                            <div class="txttest1 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Next Best</div>
                            <div class="txttest2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Action</div>
                            <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                ABAKA’s AI-recommendation engine predicts what Next Best Action will resonate and activate a customer response, and deliver it to the right people, at the right time and through the right channel with our curated content feed platform, personalized emails, in-app or push notifications.
                             </div>
                            <a routerLink="/solutions">
                                <div class="txttest4 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Read More</div>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="canvasBoxTp1">
                            <ng-lottie [options]="options1" (animationCreated)="animationCreated($event)"></ng-lottie>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="row d-flex align-items-center justify-content-between">
                    <div class="col-md-5 d-flex  align-items-center ">
                        <div data-aos="fade-up">
                            <div class="txttest1 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Data </div>
                            <div class="txttest2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Ecosystem</div>
                            <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                Bring all your data in one place. Augment your dataset for enhanced customer insights.
                             </div>
                            <a routerLink="/solutions">
                                <div class="txttest4 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Read More</div>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="canvasBoxTp1">
                            <ng-lottie [options]="options2" (animationCreated)="animationCreated($event)"></ng-lottie>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="row d-flex align-items-center justify-content-between">
                    <div class="col-md-5 d-flex  align-items-center ">
                        <div data-aos="fade-up">
                            <div class="txttest1 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Templated Recommendations </div>
                            <div class="txttest2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">& Digital Experiences</div>
                            <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                Library of 5000+ curated templates supporting omnichannel personalized engagement.
                             </div>
                            <a routerLink="/solutions">
                                <div class="txttest4 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Read More</div>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="canvasBoxTp1">
                            <ng-lottie [options]="options3" (animationCreated)="animationCreated($event)"></ng-lottie>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="row d-flex align-items-center justify-content-between">
                    <div class="col-md-5 d-flex  align-items-center ">
                        <div data-aos="fade-up">
                            <div class="txttest1 wow fadeInRight singleLineBx" data-wow-duration="1s" data-wow-delay="0.5s">Conversational <span class="txttest2">AI</span></div>
                            <!-- <div class="txttest2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s"></div> -->
                            <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                NLP models combine user behavioral & financial data to provide personalized guidance at scale.
                             </div>
                             <a routerLink="/solutions">
                                <div class="txttest4 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Read More</div>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="canvasBoxTp1">
                            <ng-lottie [options]="options5" (animationCreated)="animationCreated($event)"></ng-lottie>
                        </div>
                        
                    </div>
                </div>
            </div>
           
            <div class="item">
                <div class="row d-flex align-items-center justify-content-between">
                    <div class="col-md-7 d-flex  align-items-center ">
                        <div data-aos="fade-up">
                            <div class="txttest1 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Goal Based Saving &</div>
                            <div class="txttest2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Retirement Planning</div>
                            <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                Customer engagement through data driven goal setting and retirement planning.
                             </div>
                            <a routerLink="/solutions">
                                <div class="txttest4 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Read More</div>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="canvasBoxTp1">
                            <ng-lottie [options]="options4" (animationCreated)="animationCreated($event)"></ng-lottie>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div>
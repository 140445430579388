<div class="Abk-cnt-wrp" data-aos="fade-up">
    <div class="content-box">
      <div class="PartnerSec1">
        <app-mainblock></app-mainblock>
      </div>
      <div id="ourpartners" class="PartnerSec2">
        <app-ourpartnersblock></app-ourpartnersblock>
      </div>
      <div id="becomepartner" class="PartnerSec3">
        <app-becomeapartner></app-becomeapartner>
      </div>
      <!-- <div class="PartnerSec4">
        <app-techalliances></app-techalliances>
      </div> -->
      <div id="requestdemo" class="PartnerSec5">
        <!-- <app-partnersform></app-partnersform> -->
        <app-getintouch></app-getintouch>
      </div>
    </div>
</div>
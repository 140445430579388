<div class="solutionGrdboxes">
        
    <div id="bestaction" class="solutionGridBox wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
        <div class="container">
            <div class="row d-flex align-items-center justify-content-between">
                
                <div class="col-md-5 d-flex  align-items-center justify-content-start">
                    <div data-aos="fade-up">
                        <div class="txttest1 wow fadeInUp SingleLineBlk" data-wow-duration="1s" data-wow-delay="0.5s">The Next <span class="txttest2">Best Action</span></div>
                        <!-- <div class="txttest2 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Best Action</div> -->
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            Next Best Action is a proven AI-recommendation engine in production with global banks and insurers, constantly learning from our vast ecosystem of clients, customer data and financial products. It addresses the need for delivering personalized customer life journeys with beautiful omnichannel digital experiences. 
                         </div>
                         
                            <div class="txttest4 wow fadeInUp scrolltocontact cursor-pointer" data-wow-duration="1s" data-wow-delay="0.5s">Contact us</div>
                        
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="canvasBoxTp1">
                        <ng-lottie [options]="options1" (animationCreated)="animationCreated($event)"></ng-lottie>
                    </div>
                </div>
            </div>
            <div class="BottomTxt1">
                <div class="">
                    <div data-aos="fade-up">
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            ABAKA supports the digital transformation journey with our cloud-based Enterprise SaaS     platform with a library of modular and customizable apps. The solution is designed to     integrate with a client’s internal systems, business rules, and customer engagement     requirements and enhance their digital customer experiences and increase their         revenue.
                        </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            <b>How Does It Work? </b>
                        </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s"><b>ABAKA’s Next Best Action runs on our machine learning and behavioural persona-based segmentation.</b> </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">It delivers advanced customer insights and intelligent nudges, that empowers financial institutions to predict which message and content will resonate and activate a positive customer response for increased engagement, retention, product conversion, and up-sell.</div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">It is further the only solution within the market, which provides you with all the end-to-end architectural components that are required to work across the multiple stages leading up to the final delivery of the digital customer experience.</div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s"><b>Available as Plug and Play</b></div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Unlock value from data, efficient use of AI to process data to produce customer intelligence.</div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s"><b>API Driven Platform</b></div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">API-driven platform, Plug and Play solutions ready to deploy. Secured encrypted data ecosystem, cloud provisioning, full data privacy compliance.</div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s"><b> Outdated Processes</b></div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">With built-in digital customer experiences, Next Best Action addresses the need for delivering personalized customer life journeys with beautiful omnichannel digital experiences.</div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    <div id="ecosystem" class="solutionGridBox wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
        <div class="container">
            <div class="row d-flex align-items-center justify-content-between">
                <div class="col-md-7">
                    <div class="canvasBoxTp1">
                        <ng-lottie [options]="options2" (animationCreated)="animationCreated($event)"></ng-lottie>
                    </div>
                    
                </div>
                <div class="col-md-5 d-flex  align-items-center justify-content-start">
                    <div data-aos="fade-up">
                        <div class="txttest1 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Data </div>
                        <div class="txttest2 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Ecosystem</div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            By combining user’s behavioral data, transactional data, and external sources of data, Next Best Action offers contextual guidance and personalized nudges in real-time at scale. This has proven far more efficient than the one size fits all generic product push campaigns, which lacks personalisation across both content and targeting. 
                         </div>
                         
                            <div class="txttest4 wow fadeInUp scrolltocontact cursor-pointer" data-wow-duration="1s" data-wow-delay="0.5s">Contact us</div>
                        
                    </div>
                </div>
                
            </div>
            <div class="BottomTxt1">
                <div class="">
                    <div data-aos="fade-up">
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            ABAKA brings your clients data into a single data ecosystem via easy plug and play and file transfers. The creation of this ecosystem provides financial institutions with more than 200 identifying features and a new data set, which they do not have today and offers a significant input for the machine learning models.
                        </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            <b>Behavioral persona-based segmentation & machine learning models </b>
                        </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">ABAKA applies a set of universal personality traits and combines behavioural persona-based segmentation with the core of nudge theory, that people are not always rational beings and decisions are driven by a range of subconscious </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">factors such as desires, habits, social norms etc. on which our ML models are built to leverage subconscious drivers and present consumers with the right nudges to steer them towards the right financial products. </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Our Saving Data FootprintTM correlates the numerous digital data footprints consumers leave online, typically around 12,000GB of data per year, which by 2025 is estimated to be 50,000GB per year. </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">For this we cover six dimensions, each with multiple subcategories to create a complete picture of a persona: 
                            <ul>
                                <li>Behavioural 
                                </li>
                                <li>Social</li>
                                <li>Emotional</li>
                                <li>Financial</li>
                                <li>Health</li>
                                <li>Traditional personal data </li>
                            </ul>
                        </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">ABAKA’s machine learning models further combines this with key behavioural traits across Fear of Missing Out, Fear of Losing Out, Staying in Control, Indecision and Fear of the Unknown. </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">These machine learning models provide continuous customer understanding at a micro level and deployed at scale: </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            Persuading and activating users through predictive machine learning models
                        </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Analysing correlation between customers’ “Saving Data FootprintTM” and their level of interest for saving, retirement and other financial requirements</div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Reach each customer with the message and channel that are most likely to resonate and activate a positive response. So that we can target the right product, at the right time, through the right channel</div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">ABAKA applies different machine learning algorithms according to the available data and continuously evolve our understanding of how the right intelligent nudge can be personalized to each customer to drive the maximum engagement and revenue results.</div>
                        
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    <div id="recommendations" class="solutionGridBox wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
        <div class="container">
            <div class="row d-flex align-items-center justify-content-between">
                
                <div class="col-md-5 d-flex  align-items-center justify-content-start">
                    <div data-aos="fade-up">
                        <div class="txttest1 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Templated
                            Recommendations</div>
                        <div class="txttest2 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">& Digital Experiences</div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            Once our AI-recommendation engine has identified which product to recommend to which customer and when, it will select the right marketing template consisting of the right wording, image, tone, content, and delivery channel. The correct identification of this has proven to have a material impact on the product conversion and subsequent customer engagement.
                         </div>
                         
                            <div class="txttest4 wow fadeInUp scrolltocontact cursor-pointer" data-wow-duration="1s" data-wow-delay="0.5s">Contact us</div>
                        
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="canvasBoxTp1">
                        <ng-lottie [options]="options3" (animationCreated)="animationCreated($event)"></ng-lottie>
                    </div>
                </div>
            </div>
            <div class="BottomTxt1">
                <div class="">
                    <div data-aos="fade-up">
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            ABAKA offers a carefully crafted library of 5000 pre-curated marketing templates that will engage a customer with the relevant recommendations to create a positive personalized digital experience.
                        </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s"></div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            <ul>
                                <li><b>Front End Delivery</b></li>
                                <li>Through Webviews</li>
                                <li>APIs
                                </li>
                                <li>White-labeled App
                                </li>
                                <li>Intuitive UI</li>
                            </ul>
                        </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            <ul>
                                <li><b>Languages</b></li>
                                <li>Available in English, French, Thai, and Traditional Chinese</li>
                                <li>Additional in-house team at hand for coordinating and managing translations to new languages as per client requirements</li>
                            </ul>
                        </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            <ul>
                                <li><b>Channels
                                </b></li>
                                <li>SMS</li>
                                <li>Email</li>
                                <li>Push</li>
                                <li>In-app notifications
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="conversational" class="solutionGridBox wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
        <div class="container">
            <div class="row d-flex align-items-center justify-content-between">
                <div class="col-md-7">
                    <div class="canvasBoxTp1">
                        <ng-lottie [options]="options5" (animationCreated)="animationCreated($event)"></ng-lottie>
                    </div>
                    
                </div>
                <div class="col-md-5 d-flex  align-items-center justify-content-start">
                    <div data-aos="fade-up">
                     
                        <div class="txttest1 wow fadeInUp SingleLineBlk" data-wow-duration="1s" data-wow-delay="0.5s">Conversational <span class="txttest2">AI</span></div>
                        <!-- <div class="txttest2 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s"></div> -->
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            Conversational user interface and chatbot framework that impersonates a financial assistant for guidance on life, retirement, savings, and investments, for both fully digital and hybrid advice (advisor handoff, live on chat or on booking meeting). Our proprietary Natural Language Processing models take vast quantities of user behavioural and financial data to intelligently manage human like conversations and can hand-off to an adviser.
                         </div>
                         
                            <div class="txttest4 wow fadeInUp  scrolltocontact cursor-pointer" data-wow-duration="1s" data-wow-delay="0.5s">Contact us</div>
                        
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <div id="saving" class="solutionGridBox wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
        <div class="container">
            <div class="row d-flex align-items-center justify-content-between">
                
                <div class="col-md-5 d-flex  align-items-center justify-content-start">
                    <div data-aos="fade-up">
                        <div class="txttest1 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Goal Based Saving &
                        </div>
                        <div class="txttest2 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Retirement Planning</div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            Data-driven savings goal setting, tracking, retirement planning accumulation & decumulation, cash flow modelling, risk and suitability assessment, Monte-Carlo stochastic simulations for outcome & scenario driven advice, and transactional capabilities syncing goals with accounts and funds. 
                         </div>
                         
                            <div class="txttest4 wow fadeInUp  scrolltocontact cursor-pointer" data-wow-duration="1s" data-wow-delay="0.5s">Contact us</div>
                        
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="canvasBoxTp1">
                        <ng-lottie [options]="options4" (animationCreated)="animationCreated($event)"></ng-lottie>
                    </div>
                </div>
                
            </div>
            <div class="BottomTxt1">
                <div class="">
                    <div data-aos="fade-up">
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Our models combine users’ behavioral & financial data to provide personalized guidance at scale and are key to engaging customers at scale is by:</div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            <ul>
                                <li>Understanding the context of where they are in their life stage and with their level of savings to provide timely, relevant, and contextual nudges.</li>
                                <li>Identifying their behavioral and personality traits.</li>
                                <li>Understanding what the customer is hoping to achieve both in the short-term as well as the long-term (goals).</li>
                            </ul>
                        </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            <b>Proven Results & Success Metrics</b>
                        </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            Next Best Action has successfully been deployed across multiple financial institutions with fantastic results across all relevant success metrics, including:
                        </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            <b>Personalized Communications</b>
                        </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            <ul>
                                <li>Up to 500% increase in engagement
                                </li>
                                <li>38% increase in Net promoter Score (NPS)</li>
                                <li>Increase in product conversion from 3% to 31%</li>
                                <li>Weekly active user base 30%
                                </li>
                            </ul>
                        </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            <b>Next Best Action Nudge on Savings Goals: </b>
                        </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            <ul>
                                <li>Users with set up goals have proven to be more engaged than users without goals </li>
                                <li>Users who have set up goals reach average contribution rates, which are 50% higher than the average user base.
                                </li>
                                <li>76% of users to set up more than 1 savings goal
                                </li>
                            </ul>
                        </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Life Event Personalized Nudges on Increase Contribution:
                        </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            <ul>
                                <li>Life Event Personalized Nudges on Increase Contribution:
                                </li>
                            </ul>
                        </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            <b>Estimated return on investment (Net)
                            </b>
                        </div>
                        <div class="txttest3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">338% ROI over 5 years.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
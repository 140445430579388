<div *ngIf="isDesktopDevice">
    <div class="solutionMainBanner fixedNav MobSt1s">
        <!-- <div class="Slidenav">
            <div class="container">
                <div class="UrlNavTp1">
                    <span class="ClickSldr active" data-id="1">Retirement</span>
                    <span class="ClickSldr" data-id="2">Banking</span>
                    <span class="ClickSldr" data-id="3">Workplace Financial Wellness</span>
                    <span class="ClickSldr" data-id="4">Wealth Management</span>
                    <span class="ClickSldr" data-id="5">Mortgages</span>
                </div>
            </div>
        </div> -->
        <div id="verticals" class="SlideTp1">
            <div class="owl-carousel owl-theme home-slideskc">
                <div class="item" >
                    <div class="container"><div class="row d-flex align-items-center justify-content-between m-0">
                        <div class="col-md-6 d-flex  align-items-center">
                            <div data-aos="fade-up">
                                <div class="txttest2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Retirement</div>
                                <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                    <!-- <p>Ready to deploy Digital Retirement Solutions for advised and non-advised <br/> journeys, supporting both accumulation and decumulation:</p> -->
                                    <ul class="m-0 p-0">
                                        <li>Accumulation, Decumulation & Planning</li>
                                        
                                    </ul>
                                 </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="canvasBoxTp1 text-center">
                                <img src="../../../../assets/images/Retirement-min.png" alt="">
                            </div>
                            
                        </div>
                    </div></div>
                    
                </div>
                <div class="item">
                    <div class="container"><div class="row d-flex align-items-center justify-content-between m-0">
                        <div class="col-md-6 d-flex  align-items-center">
                            <div data-aos="fade-up">
                                <div class="txttest2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Banking</div>
                                <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                    <!-- <p>Ready to deploy Digital Personal<br/> Finance Solutions:</p> -->
                                    <ul class="m-0 p-0">
                                        <li>checking, Credit Cards,Loans & Overdraft </li>
                                    </ul>
                                 </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="canvasBoxTp1 text-center">
                                <img src="../../../../assets/images/ABAKA - Solutions/Banking.png" alt="">
                            </div>
                            
                        </div>
                    </div></div>
                    
                </div>
                <div class="item">
                    <div class="container"><div class="row d-flex align-items-center justify-content-between m-0">
                        <div class="col-md-6 d-flex  align-items-center">
                            <div data-aos="fade-up">
                                <div class="txttest2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Insurance</div>
                                <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                    <!-- <p>Build your custom Financial Wellness platform for Workplace solutions, <br/>supporting both employers and employees:</p> -->
                                    <ul class="m-0 p-0">
                                        <li>Property, Motor, Health, Family & Analytics</li>
                                    </ul>
                                 </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="canvasBoxTp1 text-center">
                                <img src="../../../../assets/images/ABAKA - Solutions/Wellness.png" alt="">
                            </div>
                        </div>
                    </div></div>
                    
                </div>
                <div class="item">
                    <div class="container"><div class="row d-flex align-items-center justify-content-between m-0">
                        <div class="col-md-6 d-flex  align-items-center">
                            <div data-aos="fade-up">
                                <div class="txttest2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Wealth</div>
                                <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                    <!-- <p>Ready to deploy Digital Wealth Solutions:</p> -->
                                    <ul class="m-0 p-0">
                                        <li>Saving, Investments & Goals</li>
                                    </ul>
                                 </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="canvasBoxTp1 text-center">
                                <img src="../../../../assets/images/ABAKA - Solutions/Wealth-Management.png" alt="">
                            </div>
                            
                        </div>
                    </div></div>
                    
                </div>
                <div class="item">
                    <div class="container"><div class="row d-flex align-items-center justify-content-between m-0">
                        <div class="col-md-6 d-flex  align-items-center">
                            <div data-aos="fade-up">
                                <div class="txttest2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Life</div>
                                <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                    <!-- <p>We have applied the benefits of Conversational AI to our mortgage module. <br/>
                                         People speak freely with chatbots. They can ask as many questions as they<br/>
                                         want without the fear of being seen as stupid or feeling embarrassment <br/>
                                         that they are talking up their time. It results in a higher volume of leads for <br/>
                                         our clients. </p> -->
                                    <ul class="m-0 p-0">
                                        <li>Income, Illness, Death, Saving & Investment-linked</li>
                                    </ul>
                                 </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="canvasBoxTp1 text-center">
                                <img src="../../../../assets/images/ABAKA - Solutions/Mortgage.png" alt="">
                            </div>
                            
                        </div>
                    </div></div>
                    
                </div>
            </div>
        </div>
    </div>
    
</div>
<div  *ngIf="isMobile||isTablet">
    <div class="solutionMainBanner fixedNav MobSt1s">
        <!-- <div class="Slidenav">
            <div class="container">
                <div class="UrlNavTp1">
                    <span class="ClickSldr active" data-id="1">Retirement</span>
                    <span class="ClickSldr" data-id="2">Banking</span>
                    <span class="ClickSldr" data-id="3">Workplace Financial Wellness</span>
                    <span class="ClickSldr" data-id="4">Wealth Management</span>
                    <span class="ClickSldr" data-id="5">Mortgages</span>
                </div>
            </div>
        </div> -->
        <div id="verticals" class="SlideTp1">
            <div class="owl-carousel owl-theme home-slideskc">
                <div class="item" >
                    <div class="row d-flex align-items-center justify-content-between m-0">
                        <div class="col-md-6 d-flex  align-items-center">
                            <div data-aos="fade-up">
                                <div class="txttest2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Retirement</div>
                                <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                    <!-- <p>Ready to deploy Digital Retirement Solutions for advised and non-advised <br/> journeys, supporting both accumulation and decumulation:</p> -->
                                    <ul class="m-0 p-0">
                                        <li>Accumulation, Decumulation & Planning</li>
                                        
                                    </ul>
                                 </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="canvasBoxTp1 text-center">
                                <img src="../../../../assets/images/Retirement-min.png" alt="">
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="row d-flex align-items-center justify-content-between m-0">
                        <div class="col-md-6 d-flex  align-items-center">
                            <div data-aos="fade-up">
                                <div class="txttest2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Banking</div>
                                <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                    <!-- <p>Ready to deploy Digital Personal<br/> Finance Solutions:</p> -->
                                    <ul class="m-0 p-0">
                                        <li>checking, Credit Cards,Loans & Overdraft </li>
                                    </ul>
                                 </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="canvasBoxTp1 text-center">
                                <img src="../../../../assets/images/ABAKA - Solutions/Banking.png" alt="">
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="row d-flex align-items-center justify-content-between m-0">
                        <div class="col-md-6 d-flex  align-items-center">
                            <div data-aos="fade-up">
                                <div class="txttest2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Insurance</div>
                                <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                    <!-- <p>Build your custom Financial Wellness platform for Workplace solutions, <br/>supporting both employers and employees:</p> -->
                                    <ul class="m-0 p-0">
                                        <li>Property, Motor, Health, Family & Analytics</li>
                                    </ul>
                                 </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="canvasBoxTp1 text-center">
                                <img src="../../../../assets/images/ABAKA - Solutions/Wellness.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="row d-flex align-items-center justify-content-between m-0">
                        <div class="col-md-6 d-flex  align-items-center">
                            <div data-aos="fade-up">
                                <div class="txttest2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Wealth</div>
                                <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                    <!-- <p>Ready to deploy Digital Wealth Solutions:</p> -->
                                    <ul class="m-0 p-0">
                                        <li>Saving, Investments & Goals</li>
                                    </ul>
                                 </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="canvasBoxTp1 text-center">
                                <img src="../../../../assets/images/ABAKA - Solutions/Wealth-Management.png" alt="">
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="row d-flex align-items-center justify-content-between m-0">
                        <div class="col-md-6 d-flex  align-items-center">
                            <div data-aos="fade-up">
                                <div class="txttest2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Life</div>
                                <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                    <!-- <p>We have applied the benefits of Conversational AI to our mortgage module. <br/>
                                         People speak freely with chatbots. They can ask as many questions as they<br/>
                                         want without the fear of being seen as stupid or feeling embarrassment <br/>
                                         that they are talking up their time. It results in a higher volume of leads for <br/>
                                         our clients. </p> -->
                                    <ul class="m-0 p-0">
                                        <li>Income, Illness, Death, Saving & Investment-linked</li>
                                    </ul>
                                 </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="canvasBoxTp1 text-center">
                                <img src="../../../../assets/images/ABAKA - Solutions/Mortgage.png" alt="">
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>


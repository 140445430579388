<div class="Abk-cnt-wrp homePage">
    <div class="content-box">
        <div id="afi" class="" #artificialfinancialinteligence>
          <app-heroslider1></app-heroslider1>
        </div>
        <div id="clients" #clients class="SectionBox wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">
            <app-testimonial1></app-testimonial1>
        </div>
        <div id="slider1" class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
          <div class="container">
            <app-slider1></app-slider1>
          </div>
        </div>
        <div id="metrics" class="SectionBox wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
          <div class=" wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
            <app-successmetrics></app-successmetrics>
          </div>
        </div>
        <div id="partners" class="SectionBox">
          <div class="container">
            <app-ourprtners></app-ourprtners>
          </div>
        </div>
        <div class="SectionBox">
            <app-homevideo1></app-homevideo1>
        </div>
        
        <div id="abakastory" class="SectionBox">
            <app-abakastory></app-abakastory>
        </div>
        <div id="request" class="SectionBox enquiryForm">
            <app-getintouch></app-getintouch>
        </div>
        <div class="SectionBox">
            <app-homevideo2></app-homevideo2>
        </div>
    </div>
</div>
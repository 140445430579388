import { Component, OnInit } from '@angular/core';
import { SendDataService } from './../../services/send-data.service';

@Component({
  selector: 'app-getintouch',
  templateUrl: './getintouch.component.html',
  styleUrls: ['./getintouch.component.scss']
})
export class GetintouchComponent implements OnInit {

  constructor(
    private sendData:SendDataService, 
  ) { }

  sucessMessage: boolean = false;

  ngOnInit(): void {
  }

  onSubmit(getInTouchData){
    this.sendData.getInTouch(getInTouchData).subscribe( data =>{
      this.sucessMessage = data.success;
    })
  }

}

<div *ngIf="isDesktopDevice">
    <div class="MainSliderBox" data-aos="fade-up">
        <div>
                <div class="container position-relative">
                    <ng-lottie [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>
                    <div class="MainSliderTxtBox">
                        <div class="row m-0">
                            <div class="col-md-6">
                                <ngx-typed-js [strings]="['Artificial Financial<br/>Intelligence™', 'Artificial Financial<br/>Intelligence™']" [typeSpeed]="100" [loop]="true">
                                    <div class="mnSldrTxt1">
                                        <span class="typing"></span>
                                    </div>
                                </ngx-typed-js>
                                <div class="mnSldrTxt2 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                                    AI-powered recommendation engine helping the financial <br/>
                                    industry deliver hyper-personalized digital <br/>
                                    experiences to their customers at scale. 
                                </div>
                                
                            </div>
                            <div class="col-md-6">&nbsp;</div>
                        </div>
                    </div>
                    <div class="HmBtn1 text-center">
                        
                        <!-- <a routerLink="/next-best-action">
                            <span class="HmBtnClk1  wow fadeInUp nextbtn1 cursor-pointer"  data-wow-duration="1s" data-wow-delay="0.5s">Explore the <span>NEXT BEST ACTION</span></span>
                        </a> -->
                        <span class="HmBtnClk1  wow fadeInUp nextbtn1 cursor-pointer"  data-wow-duration="1s" data-wow-delay="0.5s">Explore the <span>NEXT BEST ACTION</span></span>
                    </div>
                </div>
        </div>
        
    </div>
</div>
<div *ngIf="isMobile||isTablet">
    <div class="MainSliderBox">
        <div>
            
            <div class="container">
                <div class="MainSliderTxtBox">
                    <div class="row m-0">
                        <div class="col-md-6 text-center">
                            <ngx-typed-js [strings]="['Artificial Financial<br/>Intelligence™', 'Artificial Financial<br/>Intelligence™']" [typeSpeed]="100" [loop]="true">
                                <div class="mnSldrTxt1">
                                    <span class="typing"></span>
                                </div>
                            </ngx-typed-js>
                            <div class="mnSldrTxt2 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                                AI-powered recommendation engine helping the financial <br/>
                                industry deliver hyper-personalized digital 
                                experiences to their customers at scale. 
                            </div>
                            <div class="HmBtn1 text-center">
                                
                                <span class="HmBtnClk1  wow fadeInUp nextbtn1 cursor-pointer"  data-wow-duration="1s" data-wow-delay="0.5s">Explore the <span>NEXT BEST ACTION</span></span>
                            
                            </div>
                        </div>
                        <div class="col-md-6">&nbsp;</div>
                    </div>
                </div>
            </div>
            <ng-lottie [options]="options2" (animationCreated)="animationCreated($event)"></ng-lottie>
        </div>
    </div>
</div>
<div class="EnqrFrm">
    
    <div class="container">
        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6">
                <div class="frmTp1">
                    <div class="frmTp1Txt1 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Take A Step</div>
                    <div class="frmTp1Txt2 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Towards Intelligence</div>
                    <div class="frmTp1Txt3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">If you are interested in enabling your business to deliver the best financial solutions to your customers, request a demo with us today.</div>
                    <div class="FormWrp1 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                        <form #getinTouch="ngForm" (ngSubmit)="onSubmit(getinTouch.value)">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">
                                        <input type="text" class="form-control" id="formGroupExampleInput" placeholder="First name" name="firstname" ngModel>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                        <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Last name" name="lastname" ngModel>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">
                                        <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Company name" name="company_name" ngModel>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                        <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Company size" name="company_size" ngModel>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">
                                        <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Email" name="email" ngModel>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                        <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Phone" name="phone" ngModel>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">
                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" name="message" ngModel>Type your enquiry Here...</textarea>
                                    </div>
                                </div>
                                <div *ngIf="sucessMessage" class="col-md-12">
                                    <div class="alert alert-success" role="alert">
                                        <h4 class="alert-heading">Well done!</h4>
                                        <p>Message sent successfully </p>
                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                          </button>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                        <button type="submit" class="btn btn-primary mb-2">Send your enquiry</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>
    </div>
</div>
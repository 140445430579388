<header class="Abk-Header">
  <div *ngIf="isMobile||isTablet">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center">
        <div class="LogoMob"><a routerLink="/" ><img src="assets/images/abaka-logo.png" alt="Abaka UAE"></a></div>
        <div class="MobMenu"><i class="flaticon-menu startIcon"></i></div>
      </div>
    </div>
  </div>
  <div class="bgBlack closeIcon">&nbsp;</div>
  <div class="MenuNav">
    
      <div class="menuWrp">
        <div class="Abk-HeaderNav">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <div class="LogoMob"><a routerLink="/" ><img src="assets/images/abaka-logo.png" alt="Abaka UAE"></a></div>
              <div class="MobMenu"><i class="bx bx-x closeIcon"></i></div>
            </div>
          </div>
        </div>
        <ul>
          <li class="nav-item">
            <a class="nav-link">
             <span class="mbLnk1">The ABAKA Platform</span>
              <i class="bx bxs-chevron-down CloseMenu btn1"></i>
              <i class="bx bxs-chevron-up CloseMenu btn2"></i>
            </a>
            <ul class="dropdown-menu-mobile ">
              <li class="nav-item"><a (click)="toScroll('afi');" (click)="clients();" class="nav-link">Artificial Financial Intelligence</a></li>
              <li class="nav-item"><a (click)="toScroll('clients');" routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Clients</a></li>
              <li class="nav-item"><a (click)="toScroll('slider1');" routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products & Services</a></li>
              <li class="nav-item"><a (click)="toScroll('metrics');" routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Success Metrics</a></li>
              <li class="nav-item"><a (click)="toScroll('partners');"  routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Partners</a></li>
              <li class="nav-item"><a (click)="toScroll('abakastory');"  routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">ABAKA's Story</a></li>
              <li class="nav-item"><a (click)="toScroll('request');"  routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Request a Demo</a></li>
          </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link">
              <span class="mbLnk1">About</span>
              <i class="bx bxs-chevron-down CloseMenu btn1"></i>
              <i class="bx bxs-chevron-up CloseMenu btn2"></i>
            </a>
            <ul class="dropdown-menu-mobile ">
              <li class="nav-item"><a (click)="toScroll('founders');" routerLink="/aboutus" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Our Founders</a></li>
              <li class="nav-item"><a (click)="toScroll('investors');" routerLink="/aboutus" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Investors</a></li>
              <li class="nav-item"><a (click)="toScroll('careers');" routerLink="/aboutus" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Careers </a></li>
              <li class="nav-item"><a (click)="toScroll('locations');" routerLink="/aboutus" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Locations</a></li>
          </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link">
              <span class="mbLnk1">Solutions</span>
              <i class="bx bxs-chevron-down CloseMenu btn1"></i>
              <i class="bx bxs-chevron-up CloseMenu btn2"></i>
            </a>
            <ul class="dropdown-menu-mobile ">
              <li class="nav-item"><a (click)="toScroll('verticals');" routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Verticals</a></li>
                    <li class="nav-item"><a (click)="toScroll('bestaction');" routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Next Best Action</a></li>
                    <li class="nav-item"><a (click)="toScroll('ecosystem');" routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Date Ecosystem </a></li>
                    <li class="nav-item"><a (click)="toScroll('recommendations');" routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Templates Recommendations</a></li>
                    <li class="nav-item"><a (click)="toScroll('conversational');" routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Conversational AI </a></li>
                    <li class="nav-item"><a (click)="toScroll('saving');" routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Saving & Retirement Planning</a></li>
          </ul>
             
          </li>
          <li class="nav-item">
            <a class="nav-link">
              <span class="mbLnk1">Resources</span>
              <i class="bx bxs-chevron-down CloseMenu btn1"></i>
              <i class="bx bxs-chevron-up CloseMenu btn2"></i>
            </a>
            <ul class="dropdown-menu-mobile ">
                <li class="nav-item"><a (click)="toScroll('blogs');" routerLink="/resources" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blogs</a></li>
                <li class="nav-item"><a (click)="toScroll('news');" routerLink="/resources" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News</a></li>
                <li class="nav-item"><a (click)="toScroll('events');" routerLink="/resources" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Events</a></li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link">
              <span class="mbLnk1">Partners</span>
              <i class="bx bxs-chevron-down CloseMenu btn1"></i>
              <i class="bx bxs-chevron-up CloseMenu btn2"></i>
            </a>
            <ul class="dropdown-menu-mobile ">
              <li class="nav-item"><a (click)="toScroll('ourpartners');" routerLink="/partners" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Our Partners </a></li>
                    <li class="nav-item"><a (click)="toScroll('becomepartner');" routerLink="/partners" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Become a Partner</a></li>
                    <li class="nav-item"><a (click)="toScroll('requestdemo');" routerLink="/partners" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Request a Demo</a></li>
          </ul>
          </li>
        </ul>
        <div class="text-center">
          <a class="GtBtn" routerLink="/">Get Started</a>
        </div>
      </div>
  </div>
  <div *ngIf="isDesktopDevice">
    <div class="container">
      <div class="Hd-tp-1">
          <div class="Hd-tp-1-row d-flex justify-content-between">
            <div data-aos="fade-up">
              <a routerLink="/" ><img src="assets/images/abaka-logo.png" alt="Abaka UAE"></a>
            </div>
            <div>

              <ul class="nav" data-aos="fade-up">
                <li class="nav-item">
                  <a class="nav-link" routerLink="/" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">The ABAKA Platform </a>
                  <ul class="dropdown-menu">
                    <li class="nav-item"><a (click)="toScroll('afi');" (click)="clients();" class="nav-link">Artificial Financial Intelligence</a></li>
                    <li class="nav-item"><a (click)="toScroll('clients');" routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Clients</a></li>
                    <li class="nav-item"><a (click)="toScroll('slider1');" routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products & Services</a></li>
                    <li class="nav-item"><a (click)="toScroll('metrics');" routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Success Metrics</a></li>
                    <li class="nav-item"><a (click)="toScroll('partners');"  routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Partners</a></li>
                    <li class="nav-item"><a (click)="toScroll('abakastory');"  routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">ABAKA's Story</a></li>
                    <li class="nav-item"><a (click)="toScroll('request');"  routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Request a Demo</a></li>
                </ul>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/aboutus" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a>
                  <ul class="dropdown-menu">
                    <li class="nav-item"><a (click)="toScroll('founders');" routerLink="/aboutus" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Our Founders</a></li>
                    <li class="nav-item"><a (click)="toScroll('investors');" routerLink="/aboutus" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Investors</a></li>
                    <li class="nav-item"><a (click)="toScroll('careers');" routerLink="/aboutus" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Careers </a></li>
                    <li class="nav-item"><a (click)="toScroll('locations');" routerLink="/aboutus" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Locations</a></li>
                </ul>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/solutions" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Solutions</a>
                  <ul class="dropdown-menu">
                    <li class="nav-item"><a (click)="toScroll('verticals');" routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Verticals</a></li>
                    <li class="nav-item"><a (click)="toScroll('bestaction');" routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Next Best Action</a></li>
                    <li class="nav-item"><a (click)="toScroll('ecosystem');" routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Date Ecosystem </a></li>
                    <li class="nav-item"><a (click)="toScroll('recommendations');" routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Templates Recommendations</a></li>
                    <li class="nav-item"><a (click)="toScroll('conversational');" routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Conversational AI </a></li>
                    <li class="nav-item"><a (click)="toScroll('saving');" routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Saving & Retirement Planning</a></li>
                </ul>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/resources" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Resources</a>
                  <ul class="dropdown-menu">
                    <li class="nav-item"><a (click)="toScroll('blogs');" routerLink="/resources" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blogs</a></li>
                    <li class="nav-item"><a (click)="toScroll('news');" routerLink="/resources" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News</a></li>
                    <li class="nav-item"><a (click)="toScroll('events');" routerLink="/resources" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Events</a></li>
    
                </ul>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/partners" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Partners</a>
                  <ul class="dropdown-menu">
                    <li class="nav-item"><a (click)="toScroll('ourpartners');" routerLink="/partners" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Our Partners </a></li>
                    <li class="nav-item"><a (click)="toScroll('becomepartner');" routerLink="/partners" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Become a Partner</a></li>
                    <li class="nav-item"><a (click)="toScroll('requestdemo');" routerLink="/partners" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Request a Demo</a></li>
    
                </ul>
                </li>
              </ul>
            </div>
          </div> 
      </div>
  </div>
  </div>
    
  </header>
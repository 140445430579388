import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
@Component({
  selector: 'app-abakastory',
  templateUrl: './abakastory.component.html',
  styleUrls: ['./abakastory.component.scss']
})
export class AbakastoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  options: AnimationOptions = {
    path: '/assets/webcanvas/Akaba-story.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
}

<div class="internalPageTp1">
    <div class="HdTpPg1">
        <div class="container">
            Privacy Policy
        </div>
    </div>
    <div class="cont-box-1">
        <div class="container">
            <div class="content-text">

                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s"><b>Updated: 01 September 2021</b>
                </p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s"><b>INTRODUCTION</b></p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">This document gives notice how
                    Abaka, (”we”, “our”, “us”, “Abaka”) handle the personal data as defined by European data protection
                    laws (“Personal Information”) of our customers, suppliers, website users and other third parties
                    where:</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">we are processing the Personal
                    Information in the EEA or we are processing data of EEA residents irrespective of whether the
                    processing takes place inside or outside of the EEA.</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">This Notice describes the practices
                    we have adopted with respect to processing Personal Information as a processor including the
                    collection, use, storage or disclosure of Personal Information: (i) when you interact with us as a
                    customer, vendor, partner or sub-contractor; (ii) on our websites that link to this Notice
                    (collectively the "Sites"); (ii) when you interact with our support centre or other online forums;
                    or (iii) when you participate in our webinars, events, trade shows and demonstrations of our
                    products and services. It also explains how we collect information through the use of cookies and
                    related technologies.</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">It also describes your data
                    protection rights, including a right to object to some of the processing which we carry out or where
                    we rely on consent, how to withdraw that consent. More information about your rights, and how to
                    exercise them, is set out in the "What rights do you have?" section below.</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s"><b>UPDATES TO THIS NOTICE</b></p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">We may amend this Notice from time
                    to time, should it become necessary or advisable to do so to comply with regulatory requirements or
                    best practices. The most recent modification date of this Notice will appear at the top of this
                    page. If we materially change our practices in processing Personal Information, we will notify you
                    as appropriate.</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s"><b>WHAT PERSONAL INFORMATION DO WE
                        COLLECT?</b></p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">We collect and process Personal
                    Information about you when you interact with us or our Sites. This includes:</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s"><b>Customer or Prospect
                        Information:</b>Customer or potential customer’s name, email address, business address,
                    company-related information and website registration details such as user name/password details.</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s"><b>Event Attendee and/or Sponsor
                        Information:</b>Name, email address, business address, company-related information, travel
                    arrangements and meal preferences.</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s"><b>Usage
                        Information:</b>information collected from our Sites, including pages visited and documents
                    viewed and information about the browser, device or application you used to access the site. Some of
                    this information is collected using cookies and related technologies. To learn more, please see
                    below.</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s"><b>HOW DO WE USE YOUR PERSONAL
                        INFORMATION, AND WHAT IS THE LEGAL BASIS FOR THIS USE?</b></p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">We process Personal Information for
                    the following purposes:</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">To fulfil a contract or to take
                    steps linked to a contract. This includes:</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                
                </p>
                <ul  class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                    <li>providing products and services, including customer support; and</li>
                    <li>sending you information related to our products and services.</li>
                </ul>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                    As required by Abaka to conduct our business and pursue our legitimate interests as listed below,
                    and where our interests are not overridden by your data protection rights, in particular to:
                
                </p>
                <ul  class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                    <li>administer our Sites, conduct identity verification and other fraud detection activities;</li>
                    <li>monitor, maintain and improve the performance of our Sites, analyse trends, usage and activities
                        in connection with our products and services, validate users and ensure their technological
                        compatibility with users, and optimize our marketing efforts and measure the effectiveness of
                        our advertising campaigns;</li>
                    <li>improve and personalize your experience with us and our Sites and to tailor and send content,
                        advertisements and offers to you (where consent is not required by applicable laws);</li>
                    <li>manage our own internal functions such as keeping our Sites secure, management and corporate
                        reporting, internal research and analytics and to improve business efficacies;</li>
                    <li>enforce compliance with our terms of use and other policies or otherwise in connection with
                        legal claims, compliance, regulatory and investigatory purposes as necessary (including
                        disclosure of such information in connection with legal process or litigation) or to prevent,
                        investigate and/or report fraud, terrorism, misrepresentation, security incidents or crime;</li>
                    <li>create aggregate and statistical data (which cannot be used to identify you). For example,
                        aggregate data may include data that describes the general demographics, usage or other
                        characteristics of a Site's users. We reserve the right to transfer and/or sell aggregate or
                        group data about a Site's users for lawful purposes</li>
                    <li>respond to any comments or complaints you may send us;</li>
                </ul>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                    Where you give us your consent:
                
                </p>
                <ul  class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                    <li>where you ask us to send marketing information via a medium where we need your consent (for
                        example email marketing in certain countries). For more information about how to modify your
                        preferences about marketing communications, please see below;</li>
                    <li>where you give us consent to place cookies or similar technologies; and</li>
                    <li>on other occasions where we ask for your consent, for the purpose we explain at the time.</li>
                </ul>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">For purposes which are required by
                    law, such as responding to requests by government or law enforcement authorities conducting an
                    investigation.</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">In order to purchase our products
                    and services, the provision of certain Personal Information is mandatory: if relevant Personal
                    Information is not provided, then we will not be able to provide you with our products and services.
                    All other provision of your Personal Information is optional. If you provide Personal Information to
                    us for a certain reason, we will use the Personal Information in connection with the reason for
                    which it was provided.</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                    <b>WITHDRAWING CONSENT OR OTHERWISE OBJECTING TO DIRECT MARKETING</b>
                </p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Wherever we rely on your consent,
                    you will always be able to withdraw that consent, and we will stop the processing unless we have
                    another legal ground for processing your Personal Information. In some cases, we are able to send
                    you direct marketing without your consent, where we rely on our legitimate interests (and where
                    other laws do not require consent). You have an absolute right to opt-out of direct marketing
                    (including profiling) we carry out for direct marketing, at any time. You can do this by following
                    the instructions in the communication where this is an electronic message, or by visiting our
                    Preference Centre. Even if you opt out of marketing communications from us, please note that you may
                    still be contacted with service related communications (such as software upgrades or release
                    availability).</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                    <b>WHO WILL WE SHARE THIS PERSONAL INFORMATION WITH?</b>
                </p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">We share Personal Information
                    amongst our group companies for the purposes of delivering our products and services, managing your
                    accounts, hosting, IT, security, support, billing, marketing, and communications.</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">We use third party service
                    providers, who will process Personal Information on our behalf. We use third parties for customer
                    relationship management, website and systems hosting, maintenance, software upgrades and marketing.
                </p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">We may transfer Personal
                    Information in connection with a contemplated reorganization, sale, bankruptcy or transfer of all or
                    a portion of our business or assets. Following such a sale or transfer, the entity to which we
                    transferred Personal Information will be the data controller and point of contact for any inquiries
                    concerning the processing of that Personal Information.</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">We will comply with requests to
                    disclose Personal Information where required by local law or government authorities to comply with a
                    legal obligation, and where permissible, we will provide advance notice of such disclosure to the
                    individuals concerned.</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                    <b>WHERE IS YOUR PERSONAL INFORMATION STORED AND PROCESSED?</b>
                </p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Abaka is a global business. To
                    provide our products and services, we transfer Personal Information to other countries, including
                    countries outside of the European Economic Area and Switzerland which may have different data
                    protection standards to those which apply to your country of residence. Where information is
                    transferred outside the EEA and Switzerland, and where this is to a group company or vendor in a
                    country that is not subject to an adequacy decision by the EU Commission, data is adequately
                    protected by EU Commission approved standard contractual clauses or Swiss standard contractual
                    clauses or a vendor's Processor Binding Corporate Rules. You can obtain more information about the
                    relevant mechanism by contacting us on the contact details set out below.</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                    <b>WHAT RIGHTS DO YOU HAVE?</b>
                </p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">We honour data subjects’ rights
                    under applicable law to access, correct, update, erase, disable and block their Personal Information
                    when lawfully requested to do so.</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">In some circumstances, you have the
                    right to ask us for a copy of your Personal Information; to correct, delete or restrict (stop any
                    active) processing of your Personal Information; and to obtain the Personal Information you provide
                    to us for a contract or with your consent in a structured, machine readable format, and to ask us to
                    share (port) this Personal Information to another controller. In addition, you can object to the
                    processing of your Personal Information or where we rely on your consent, withdraw your consent (see
                    Withdrawing consent or otherwise objecting to direct marketing section above for more information).
                </p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">These rights may be limited, for
                    example if fulfilling your request would reveal Personal Information about another person, or if you
                    ask us to delete information which we are required by law to keep or have compelling legitimate
                    interests in keeping.</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">To exercise any of these rights,
                    you can get in touch with us by email contact@abaka.com. If you have unresolved concerns, you may
                    have the right to complain to the relevant data protection authority in your country of residence or
                    place of the alleged infringement.</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s"><b>HOW LONG WILL WE KEEP YOUR
                        PERSONAL INFORMATION?</b></p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Your Personal Information will be
                    retained for as long as necessary to fulfil the purposes outlined in this Notice or to comply with
                    applicable legal requirements. For more information, please contact us using the details set out
                    below.</p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s"><b>HOW TO CONTACT US</b></p>
                <p class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">For further information on our
                    privacy policies and practices relating to the handling of Personal Information, contact our Privacy
                    Department by postal mail to 111 Buckingham Palace Road SW1W 0SR, United Kingdom or by email to
                    contact@abaka.com.
                </p>
            </div>
        </div>
    </div>
</div>
<div >
    <div class="plor-responsive-nav">
        <div class="container-fluid">
            <div class="plor-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img src="assets/img/logo.png" alt="logo">
                    </a>
                </div>
            </div>
        </div>
    </div>
    
</div>


<div class="HmStryWrp">
    <div class="container">
        <div class="orPtHd10 text-center wow fadeInLeft"  data-wow-duration="1s" data-wow-delay="0.5s">
            The <span>ABAKA Story</span>
        </div>
        <div class="orPtHd11 text-center wow fadeInRight"  data-wow-duration="1s" data-wow-delay="0.5s">Artificial Financial Intelligence&nbsp;<sup>TM</sup></div>
        <div class="orPtHd12 text-center wow fadeInLeft"  data-wow-duration="1s" data-wow-delay="0.5s">
            With deep expertise & specialization in machine learning and hyper-personalization, ABAKA is a multi-award-winning provider of Artificial Financial Intelligence <sup>TM</sup> that serves the needs of global clients including Tier 1 financial institutions across three verticals covering retail banking, wealth management and insurance. 
        </div>
        <div class="orPtHd12 text-center wow fadeInRight"  data-wow-duration="1s" data-wow-delay="0.5s">
            By combining data analytics with behavioral science, we help financial institutions     deliver hyper-personalized customer experiences and build a truly customer centric     ecosystem across their range of products and services.
            Established since 2015 with headquarters in the United Kingdom and further offices     across the APAC and Europe, ABAKA has expanded with solutions now deployed across     three continents.
        </div>
        <div class="orPtHd13 text-center wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
            <a routerLink="aboutus">Read More</a>
        </div>
        <div class="storyAnim">
            <ng-lottie [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>
        </div>
    </div>
</div>
<div class="container">
    <div class="mnagementBoxWrp">
        <div class="manBoxItWrp">
            <div class="HdStyle1 wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
                The <span>management</span>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="teamMember wow fadeInLeft"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/demo.jpg" alt="">
                        <div class="teamMember1">Fahad Rachidy</div>
                        <div class="teamMember2">founder and CEO</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="teamMember wow fadeInLeft"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/demo.jpg" alt="">
                        <div class="teamMember1">Fahad Rachidy</div>
                        <div class="teamMember2">founder and CEO</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="teamMember wow fadeInLeft"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/demo.jpg" alt="">
                        <div class="teamMember1">Fahad Rachidy</div>
                        <div class="teamMember2">founder and CEO</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="teamMember wow fadeInLeft"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/demo.jpg" alt="">
                        <div class="teamMember1">Fahad Rachidy</div>
                        <div class="teamMember2">founder and CEO</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="teamMember wow fadeInLeft"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/demo.jpg" alt="">
                        <div class="teamMember1">Fahad Rachidy</div>
                        <div class="teamMember2">founder and CEO</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="teamMember wow fadeInLeft"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/demo.jpg" alt="">
                        <div class="teamMember1">Fahad Rachidy</div>
                        <div class="teamMember2">founder and CEO</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="teamMember wow fadeInLeft"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/demo.jpg" alt="">
                        <div class="teamMember1">Fahad Rachidy</div>
                        <div class="teamMember2">founder and CEO</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="teamMember wow fadeInLeft"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/demo.jpg" alt="">
                        <div class="teamMember1">Fahad Rachidy</div>
                        <div class="teamMember2">founder and CEO</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="teamMember wow fadeInLeft"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/demo.jpg" alt="">
                        <div class="teamMember1">Fahad Rachidy</div>
                        <div class="teamMember2">founder and CEO</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="teamMember wow fadeInLeft"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/demo.jpg" alt="">
                        <div class="teamMember1">Fahad Rachidy</div>
                        <div class="teamMember2">founder and CEO</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="teamMember wow fadeInLeft"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/demo.jpg" alt="">
                        <div class="teamMember1">Fahad Rachidy</div>
                        <div class="teamMember2">founder and CEO</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="teamMember wow fadeInLeft"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="../../../../assets/images/demo.jpg" alt="">
                        <div class="teamMember1">Fahad Rachidy</div>
                        <div class="teamMember2">founder and CEO</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
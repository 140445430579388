<div class="EnqrFrm" id="ContactBox">
    
    <div class="container">
        <div class="row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6">
                <div class="frmTp1">
                    <div class="frmTp1Txt1 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Take A Step</div>
                    <div class="frmTp1Txt2 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Towards Intelligence</div>
                    <div class="frmTp1Txt3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">If you are interested in enabling your business to deliver the best financial solutions to your customers, connect with us today.   </div>
                    <div class="FormWrp1 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">
                                    <input type="text" class="form-control" id="formGroupExampleInput" placeholder="First name">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                    <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Last name">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">
                                    <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Company name">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                    <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Company size">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">
                                    <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Email">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                    <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Phone">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">
                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3">Type your enquiry Here...</textarea>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                                    <button type="submit" class="btn btn-primary mb-2">Send your enquiry</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">&nbsp;</div>
        </div>
    </div>
</div>
<div class="internalPageTp1">
    <div class="HdTpPg1">
        <div class="container">
           FAQs
        </div>
    </div>
    <div class="cont-box-1">
        <div class="container">
            <div class="content-text">
                <h2>FAQs</h2>
                
<p>Dated: 02 May 2018</p>



<p>These FAQ’s provide answers to some of the most frequently asked questions about ABAKA. For additional information, feel free to contact us on support@abaka.me</p>



<p><strong>GENERAL</strong><br></p>



<p><strong><br>Q: Who is Ava?</strong><br>A: Ava is your personal financial assistant.</p>



<p><strong>Q: How does Ava work?</strong><br>A: Ava is powered by cutting-edge Artificial Intelligence technology. In our case, this means that Ava uses our proprietary technology to understand your personal financial situation and provide guidance and recommendations to optimise your savings in clear, conversational English.</p>



<p><strong>Q: What is the relationship between ABAKA and Ava?</strong><br>A: Ava is the conversational user interface for you to interact with ABAKA’s technology.</p>



<p><strong>Q: I couldn’t find ABAKA in my App Store. Why?</strong><br>A: ABAKA is not available on the App store or Google play. It is currently a web-App (which means it’s like a normal App except instead of installing it, you access it using your phone’s browser). You can register here: https://live.abaka.me.</p>



<p><strong>Q: Can I set up ABAKA on more than one device?</strong><br>A: Sure, ABAKA is available on any device (mobile, tablet, laptop, desktop).</p>



<p><strong>USING ABAKA</strong></p>



<p><strong>Q: How do I link my bank or pension accounts?</strong><br>A: All you need are your online login details for your various accounts.</p>



<p><strong>Q: I have several bank accounts, can I link them all?</strong><br>A: Absolutely, there’s no limit to the number of accounts you can link in ABAKA.</p>



<p><strong>Q: I’m having trouble linking my Barclays bank account – what can I do?</strong><br>A: Barclays uses multiple codes. You’ll need your online banking passcode/memorable name etc. details (NOT your mobile banking passcode or telephone banking details as these won’t work).</p>



<p><strong>Q: Can ABAKA help me find pensions from old employers?</strong><br>A: Yes, if you know the rough dates you were at your previous employer you can ask Ava to find your old pensions for you. If she cannot assist, you can always contact the government’s free Pension Tracing Service on 0800 731 0193 who will be able to help too.</p>



<p><strong>Q: I’ve not yet registered on my online pension portal– what do I do?</strong><br>A: We recommend that you contact your pension provider &amp; register for login details to prevent you having to update it manually every time. However, if you prefer, you can enter your pension details manually in the ABAKA App using the most recent pension statement you were sent.</p>



<p><strong>Q: The balance showing on my accounts in ABAKA isn’t the same as in my banking App – why?</strong><br>A: The information in your ABAKA App is updated every 24 hours (not real time yet, but we’re working on it!).</p>



<p><strong>Q: How is my spending categorised and how does Ava keep track of my spending/saving?</strong><br>A: Ava will classify your transactions into specific categories so you can have a better view of where your money goes.</p>



<p><strong>Q: Can I set it up my partner’s details as well to show our financial profiles separately on the App?</strong><br>A: You, can link your partner’s accounts and see how they’re doing with their savings and investments if you want to.</p>



<p><strong>Q: Can ABAKA help me with other sources of income such as rental, dividends etc?</strong><br>A: Yes, you can add additional income directly into the Income tab within the My Financial Profile section of the App.</p>



<p><strong>Q: Can you proactively act on any of the guidance given through ABAKA…for example, opening an account, or ABAKA transferring funds for pensions, etc?</strong><br>A: Yes you can, although in some cases it will depend on the set up with your account.</p>



<p><strong>Q: Can ABAKA link my non-UK accounts?</strong><br>A: Yes, but this will depend on the country. When you click on link account just enter the name of your provider and see if we can find it! Remember, if it’s not there you could also add it manually.</p>



<p><strong>Q: I have recently moved to the UK, can ABAKA factor in tax information from my previous country?</strong><br>A: ABAKA is a UK-based platform and will only recognise UK tax thresholds and regulations for now. We’re launching soon in other countries too, so bear with us!<br><strong><br>Q: Can I link my student loans using ABAKA?</strong><br>A: Not currently but we are actively working on this. If you have any ideas let us know at feedback@abaka.me.</p>



<p><strong>Q: I don’t want to receive any nudges, how can I switch them off?</strong><br>A: Notification settings can be adjusted from inside the App under ‘My Personal Profile’ or from your desktop browser. Just remember though, if you do switch off nudges you might miss important messages.</p>



<p><strong>DATA &amp; PRIVACY</strong><strong><br><br>Q: Is my data secure?<br>A: Absolutely, we are committed to ensuring your data is secured and safe. We use bank-level cyber security and encryption to ensure your data is kept secure &amp; confidential.</strong></p>



<p><strong>Q: How do you ensure cybersecurity?</strong><br>A: We have cloud-based bank level cybersecurity to keep you and your data safe and encrypted at all times (at rest, at transit). Nobody else you can see your data (that includes your employer, and us of course). We won’t share or sell your data. Your data is available anytime should you wish to access, delete or retrieve it. Please also read our Data Privacy Policy on https://abaka.me/Home/PrivacyPolicy</p>



<p><strong>Q: Why do you need my login information for my financial providers?</strong><br>A: This is how the magic happens. But don’t worry this information is encrypted at all time. Using the information you provide to Ava, she can build a complete picture of your financial position to help you optimise your expenses and savings.</p>



<p><strong>Q: How is my data used?</strong><br>A: ABAKA will never sell or resell your data. Your data is encrypted at all time. Your data can only be analysed by Ava to help you become better at managing your money.</p>



<p><strong>PENSIONS</strong></p>



<p><strong>Q: What is a DC (Defined Contribution) pension?</strong><br>A: A workplace DC pension is a new pension type where you and your employer are contributing money into a pension, which is then invested.<br><strong><br>Q: What is a DB (Defined Benefit) pension?</strong><br>A: A workplace DB pension is an old pension type where your employer gives you a percentage of your final salary for the rest of your life, depending on your years of service.</p>



<p><strong>Q: What is a SIPP (Self Invested Personal Pension) pension?</strong><br>A: Self Invested Personal Pensions (SIPP) are tax-free private savings accounts for retirement – you are in control of your financial future free to select investments.<br><strong><br>OTHER</strong></p>



<p><strong>Q: How do I unlink my account?</strong><br>A: Go to the detailed view of your account and simply click ‘delete account’.</p>



<p><strong>Q: I can’t find the answer to my question – can you help me?</strong><br>A: Sure, you can either raise a ticket with our Support Team by asking Ava to do so, or simply send us an email to support@abaka.me and we will be able to help you.</p>



<p><strong>Q: I want to delete my profile – can you help me?</strong><br>A: Sure, you can either raise a ticket with our Support Team by asking Ava to do so, or simply send us an email to support@abaka.me and we will be able to do that for you.<br><strong><br>Q: Do you sell products or earn commission based on your recommendations?</strong><br>A: No, we don’t currently distribute products nor do we take a commission.<br><strong><br>Q: Is ABAKA a taxable benefit?</strong><br>A: It depends on the set up of your employer. If in doubt please ask your HR or Reward Manager.</p>
              </div>
        </div>
    </div>
</div>

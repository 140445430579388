import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-slider1',
  templateUrl: './slider1.component.html',
  styleUrls: ['./slider1.component.scss']
})
export class Slider1Component implements OnInit {

  deviceInfo = null;
  isMobile=null;
  isTablet=null;
  isDesktopDevice=null;

  constructor(private deviceService: DeviceDetectorService) { 
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  ngOnInit(): void {
  }
  options1: AnimationOptions = {
    path: '/assets/webcanvas/section 1.json',
  };
  options2: AnimationOptions = {
    path: '/assets/webcanvas/section 2.json',
  };
  options3: AnimationOptions = {
    path: '/assets/webcanvas/section 4.json',
  };
  options4: AnimationOptions = {
    path: '/assets/webcanvas/section 5.json',
  };
  options5: AnimationOptions = {
    path: '/assets/webcanvas/section3.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

}

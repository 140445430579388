import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookietab',
  templateUrl: './cookietab.component.html',
  styleUrls: ['./cookietab.component.scss']
})
export class CookietabComponent implements OnInit {

  showCookieConsent = false;
  private cookieValue;
  constructor( private cookieService: CookieService ) { }

  ngOnInit(): void {
    
    this.cookieValue = this.cookieService.get('cookie_consent');
    if(this.cookieValue && this.cookieValue == 'accepted') {
      this.showCookieConsent = false;
    } else {
      this.showCookieConsent = true;
    }

  }
  acceptCookie(){
    
    this.cookieService.set( 'cookie_consent', 'accepted' );
    this.showCookieConsent = false;
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homevideo1',
  templateUrl: './homevideo1.component.html',
  styleUrls: ['./homevideo1.component.scss']
})
export class Homevideo1Component implements OnInit {
  isShowDiv = false ;
  constructor() { }

  ngOnInit(): void {
  }
  toggleDisplayDiv() {

    this.isShowDiv = !this.isShowDiv;
  }

}

<div class="homeVideoTp1 wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
    <div class="hover15 cursor-pointer"[hidden]="isShowDiv">
        <figure (click)=toggleDisplayDiv()>
            <img src="../../../assets/images/homevideo.jpg" alt="">
        </figure>
    </div>
    <div [hidden]="!isShowDiv">
        <div class="">
            <video controls autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" style="width: 100%;">
                <source src="../../../assets/videos/Abaka's Next Best Action Explainer.mp4" type="video/mp4">
            </video>
        </div>
    </div>
</div>
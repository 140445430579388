import { Component, OnInit } from '@angular/core';
import { GetBlogsService } from '../../../services/get-blogs.service';
import { Blogs } from '../../../models/blogs';
import { ActivatedRoute } from '@angular/router';

declare function loadBlogCarousel(): any;
@Component({
  selector: 'app-ourblogsblock',
  templateUrl: './ourblogsblock.component.html',
  styleUrls: ['./ourblogsblock.component.scss']
})

export class OurblogsblockComponent implements OnInit {

  blogs: Blogs; 
  pageCount; 
  pageNo;
  totalPages: Number;
  currentPage;

  constructor(
    private getBlogs:GetBlogsService, 
    private activatedRoute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe( params => {
      this.pageNo= params['pageNo'];
    });
    
    this.getBlogs.viewBlogs().subscribe(data =>{
      for (var i in data['data']){
        let str = this.extractContent(data['data'][i].content);
        let t = str.substring(0, 200);
        data['data'][i].content = t;
      }
       this.blogs = data['data'];
       this.totalPages = data['total_pages'];
       this.pageCount = new Array(this.totalPages);
       this.currentPage = 1;
       loadBlogCarousel();
    });

    if(this.pageNo > 0){
      this.getBlogs.viewMoreBlogs(this.pageNo).subscribe(data =>{
          this.blogs = data['data'];
          this.currentPage = this.pageNo;
      });
    }
  }
  
  extractContent(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }
}

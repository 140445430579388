<div class="Abk-cnt-wrp abtTp11">
    <div class="content-box">
      <div class="BannerTp1">
        <app-mainslider></app-mainslider>
      </div>
      <div id="founders" class="descriptionBox">
        <app-descriptionbox></app-descriptionbox>
      </div>
      <!-- <div class="ManagementBox">
        <app-themanagement></app-themanagement>
      </div> -->
      <div id="investors" class="Inverstors">
        <app-investors></app-investors>
      </div>
      <div id="careers" class="careers">
        <app-careeer></app-careeer>
      </div>
      <div id="locations" class="location">
        <app-aboutlocation></app-aboutlocation>
      </div>
      <div class="SectionBox enquiryForm">
        <!-- <app-contactarea></app-contactarea> -->
        <app-getintouch></app-getintouch>
      </div>
    </div>
</div>
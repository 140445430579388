<div class="career CareerBlock">
    <div class="container">
        <div class="HdStyle1 text-center wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
            <span>Careers</span>
        </div>
        <div class="subTtlTp1 text-center wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">We are always on the look-out for talented people to join the team.<br/>
            If you’re interested to find out more, email <a href="mailto:careers@abaka.me"><b>careers@abaka.me</b></a>
        </div>
        <div class="OpeningBox">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <div class="OpeniDet-1 text-center">
                            <div class="OpeniDet-txt-1">Software<br/> Engineer</div>
                            <div class="OpeniDet-txt-2">
                                <a href="mailto:help@abaka.me">Find out more</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="OpeniDet-1 text-center">
                            <div class="OpeniDet-txt-1">Business <br/>Analyst</div>
                            <div class="OpeniDet-txt-2">
                                <a href="mailto:help@abaka.me">Find out more</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="OpeniDet-1 text-center">
                            <div class="OpeniDet-txt-1">Data<br/> Engineer</div>
                            <div class="OpeniDet-txt-2">
                                <a href="mailto:help@abaka.me">Find out more</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="OpeniDet-1 text-center">
                            <div class="OpeniDet-txt-1">NLP Data <br/>Scientist</div>
                            <div class="OpeniDet-txt-2">
                                <a href="mailto:help@abaka.me">Find out more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="careerImg text-center wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
            <div class=" wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
                <img class=" wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s" src="../../../../assets/images/careerimg.jpg" alt="">
            </div>
        </div> -->
    </div> 
</div>
<div class="TestimonialBox">
    <div class="abstBlock">
        <ng-lottie [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>
    </div>
    <div class="container">
        
        <div class="row">
            <div class="col-md-5 d-flex align-items-center justify-content-start">
                <div  class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                    <div class="txttest1 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Hear From</div>
                    <div class="txttest2 wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">Our Clients</div>
                    <div class="txttest3 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                        Our clients have used our solutions to <br/>
                        achieve some powerful results.<br/> The ABAKA 
                        Next Best Actions covers over  <br/>10 million 
                        retail customers and policyholders.</div>
                        <a routerLink="resources">
                            <div class="txttest4 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Read More</div>
                        </a>
                </div>
            </div>
            <div class="col-md-7" >
                <div *ngIf="isDesktopDevice">
                    <div class="testmansory wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
                        <div class="container">
                        
                            <figure  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
                                <a routerLink="/resources" class="d-block">
                                    <div class="TestimonialItem bounce animated1">
                                        <div class="TestimonialItemLog"><img src="../../../assets/images/ABAKA - HearFromOurClients Logos/HSBC.png" alt=""></div>
                                        <div class="TestimonialItem1 text-left"><img src="../../../assets/images/testleft.png" alt=""></div>
                                        <div class="TestimonialItem2">“In my view ABAKA has cracked the
                                            go-to-market challenge.Focusing on savings and
                                            retirement, the proposition is very compelling.”<b>-HSBC Global Head of Digital</b></div>
                                        <div class="TestimonialItem2 text-right"><img src="../../../assets/images/testright.png" alt=""></div>
                                    </div>
                                </a>
                            </figure>
                            <figure  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
                                <a routerLink="/resources" class="d-block">
                                <div class="TestimonialItem bounce animated1" data-aos="fade-up">
                                    <div class="TestimonialItemLog"><img src="../../../assets/images/ABAKA - HearFromOurClients Logos/Prudential.png" alt=""></div>
                                    <!-- <div class="TestimonialItem1 text-left"><img src="../../../assets/images/testleft.png" alt=""></div> -->
                                    <div class="TestimonialItem2">Prudential Asia concludes that ABAKA’s platform allows them to go to market faster, and deliver hyper-personalized and contextualized digital experiences to their customers while strengthening the quality of advice provided by their agents and RM.<b>-Prudential</b></div>
                                    <!-- <div class="TestimonialItem2 text-right"><img src="../../../assets/images/testright.png" alt=""></div> -->
                                </div>
                            </a>
                            </figure>
                            <figure  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
                                <a routerLink="/resources" class="d-block">
                                <div class="TestimonialItem bounce animated1" data-aos="fade-up">
                                    <div class="TestimonialItemLog"><img src="../../../assets/images/ABAKA - HearFromOurClients Logos/OTP Bank.png" alt=""></div>
                                    <!-- <div class="TestimonialItem1 text-left"><img src="../../../assets/images/testleft.png" alt=""></div> -->
                                    <div class="TestimonialItem2">ABAKA has increased the average session time on the OTP Bank App by 32%, increased the active user base by 35%, and increased the conversion rate by 420%. Resulting in a Return On Investment of 338% for OTP.<b>-OTP Bank</b> </div>
                                    <!-- <div class="TestimonialItem2 text-right"><img src="../../../assets/images/testright.png" alt=""></div> -->
                                </div>
                            </a>
                            </figure>
                            <figure  class="wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.5s">
                                <a routerLink="/resources" class="d-block">
                                <div class="TestimonialItem bounce animated1" data-aos="fade-up">
                                    <div class="TestimonialItemLog"><img src="../../../assets/images/ABAKA - HearFromOurClients Logos/Oman Insurance.png" alt=""></div>
                                    <!-- <div class="TestimonialItem1 text-left"><img src="../../../assets/images/testleft.png" alt=""></div> -->
                                    <div class="TestimonialItem2">Helping Oman Insurance transforming the retirement and insurance market in the UAE through AI-driven digital engagement<b>-Oman Insurance</b></div>
                                    <!-- <div class="TestimonialItem2 text-right"><img src="../../../assets/images/testright.png" alt=""></div> -->
                                </div>
                            </a>
                            </figure>
                        </div>

                    </div>
                </div>
                <div *ngIf="isMobile||isTablet" class="testmansory mobile">
                    <div class="">
                        <div class="item" >
                            <a routerLink="/resources" class="d-block">
                                <div class="TestimonialItem bounce animated1">
                                    <div class="TestimonialItemLog"><img src="../../../assets/images/ABAKA - HearFromOurClients Logos/HSBC.png" alt=""></div>
                                    <div class="TestimonialItem1 text-left"><img src="../../../assets/images/testleft.png" alt=""></div>
                                    <div class="TestimonialItem2">“In my view ABAKA has cracked the
                                        go-to-market challenge. Focusing on savings and
                                        retirement, the proposition is very compelling.”<b>-HSBC Global Head of Digital</b></div>
                                    <div class="TestimonialItem2 text-right"><img src="../../../assets/images/testright.png" alt=""></div>
                                </div>
                            </a>
                        </div>
                        <div class="item" >
                            <a routerLink="/resources" class="d-block">
                                <div class="TestimonialItem bounce animated1" data-aos="fade-up">
                                    <div class="TestimonialItemLog"><img src="../../../assets/images/ABAKA - HearFromOurClients Logos/Prudential.png" alt=""></div>
                                    <!-- <div class="TestimonialItem1 text-left"><img src="../../../assets/images/testleft.png" alt=""></div> -->
                                    <div class="TestimonialItem2">Prudential Asia concludes that ABAKA’s platform allows them to go to market faster, and deliver hyper-personalized and contextualized digital experiences to their customers while strengthening the quality of advice provided by their agents and RM.<b>-Prudential</b></div>
                                    <!-- <div class="TestimonialItem2 text-right"><img src="../../../assets/images/testright.png" alt=""></div> -->
                                </div>
                            </a>
                        </div>
                        <div class="item" >
                            <a routerLink="/resources" class="d-block">
                                <div class="TestimonialItem bounce animated1" data-aos="fade-up">
                                    <div class="TestimonialItemLog"><img src="../../../assets/images/ABAKA - HearFromOurClients Logos/OTP Bank.png" alt=""></div>
                                    <!-- <div class="TestimonialItem1 text-left"><img src="../../../assets/images/testleft.png" alt=""></div> -->
                                    <div class="TestimonialItem2">ABAKA has increased the average session time on the OTP Bank App by 32%, increased the active user base by 35%, and increased the conversion rate by 420%. Resulting in a Return On Investment of 338% for OTP.<b>-OTP Bank</b> </div>
                                    <!-- <div class="TestimonialItem2 text-right"><img src="../../../assets/images/testright.png" alt=""></div> -->
                                </div>
                            </a>
                        </div>
                        <div class="item" >
                            <a routerLink="/resources" class="d-block">
                                <div class="TestimonialItem bounce animated1" data-aos="fade-up">
                                    <div class="TestimonialItemLog"><img src="../../../assets/images/ABAKA - HearFromOurClients Logos/Oman Insurance.png" alt=""></div>
                                    <!-- <div class="TestimonialItem1 text-left"><img src="../../../assets/images/testleft.png" alt=""></div> -->
                                    <div class="TestimonialItem2">Helping Oman Insurance transforming the retirement and insurance market in the UAE through AI-driven digital engagement<b>-Oman Insurance</b></div>
                                    <!-- <div class="TestimonialItem2 text-right"><img src="../../../assets/images/testright.png" alt=""></div> -->
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <!-- <ng-lottie [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie> -->
    </div>
</div>

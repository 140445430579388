<div class="SuccessMetrics text-center">
    <div class="container">
        <div class="txttest1 wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">Proven Success Metrics</div>
        <div class="txttest2 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">From Customers</div>
        <div class="OdoWrp">
            <div class="row">
                <div class="col-md-4">
                    <div class="OdooMt1 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                        Increase<br/>Engagement By
                    </div>
                    <h3 class="OdooMt2"><span class="odometer" data-count="500">00</span> %</h3>
                </div>
                <div class="col-md-4">
                    <div class="OdooMt1 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                        Increase Product<br/> Conversion From 3% To
                    </div>
                    <h3 class="OdooMt2"><span class="odometer" data-count="31">00</span> %</h3>
                </div>
                <div class="col-md-4">
                    <div class="OdooMt1 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">Increase Net <br/> Promoter Score By</div>
                    <h3 class="OdooMt2"><span  class="odometer" data-count="38">00</span> %</h3>
                </div>
            </div>
        </div>
       
    </div>
    <div class="GraphWrp">
        <ng-lottie [options]="lottieConfig" (animCreated)="handleAnimation($event)"></ng-lottie>
    </div>
    <!-- <div class="PartnerBoxWrp">
        <div class="container">
            <div class="PartnerBoxItem">
                <div class=""></div>
            </div>
        </div>
    </div> -->
</div>
<div class="blogSlider wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
    <div class="container">
        <div class="HdStyle1 wow fadeInUp text-center"  data-wow-duration="1s" data-wow-delay="0.5s">
            Our <span>Blogs</span>
        </div>

        <div class="owl-carousel owl-theme blogsldr1 ResT1">

            <div class="item" *ngFor="let bl of blogs">
                <div class="blogpad">
                    <div class="blogItm1 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s" style="background-image: url({{bl.photo}});">
                        <div class="blogItm1Content">
                            <div class="blgItemBox1">
                                <div class="row">
                                    <div class="col-md-2">
                                        <span class="ThmLft">
                                            <img src="../../../../assets/images/blogthumb.png" alt="">
                                        </span>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="tcx1">Blog<br/>Article</div>
                                    </div>
                                </div>
                            </div>
                            <div class="blgItemBox2">{{bl.title}}</div>
                            <div class="blgItemBox3">{{bl.content}}</div>
                            <div class="blgItemBox4">
                                <a routerLink="/blogdetails/{{bl.slug}}">Discover More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="item">
                <div class="blogpad">
                    <div class="blogItm1 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s" style="background-image: url(../../../../assets/images/blog1.jpg);">
                        <div class="blogItm1Content">
                            <div class="blgItemBox1">
                                <div class="row">
                                    <div class="col-md-2">
                                        <span class="ThmLft">
                                            <img src="../../../../assets/images/blogthumb.png" alt="">
                                        </span>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="tcx1">Blog<br/>Article</div>
                                    </div>
                                </div>
                            </div>
                            <div class="blgItemBox2">How to continue selling your service within the new AI regulatory framework</div>
                            <div class="blgItemBox3">As you may know, the EU has published its proposal for new regulations on the ethical use of AI in financial services.[1] But how.... do you keep selling your products and services in a way that’s compliant with the new rules?</div>
                            <div class="blgItemBox4">
                                <a routerLink="/blogdetailpage">Discover More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
               <div class="blogpad">
                <div class="blogItm1 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s" style="background-image: url(../../../../assets/images/blog2.jpg);">
                    <div class="blogItm1Content">
                        <div class="blgItemBox1">
                            <div class="row">
                                <div class="col-md-2">
                                    <span class="ThmLft">
                                        <img src="../../../../assets/images/blogthumb.png" alt="">
                                    </span>
                                </div>
                                <div class="col-md-7">
                                    <div class="tcx1">Blog<br/>Article</div>
                                </div>
                            </div>
                        </div>
                        <div class="blgItemBox2">How to continue selling your service within the new AI regulatory framework</div>
                        <div class="blgItemBox3">As you may know, the EU has published its proposal for new regulations on the ethical use of AI in financial services.[1] But how.... do you keep selling your products and services in a way that’s compliant with the new rules?</div>
                        <div class="blgItemBox4">
                            <a routerLink="/blogdetailpage">Discover More</a>
                        </div>
                    </div>
                </div>
               </div>
            </div>
            <div class="item">
                <div class="blogpad">
                    <div class="blogItm1 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s" style="background-image: url(../../../../assets/images/blog2.jpg);">
                        <div class="blogItm1Content">
                            <div class="blgItemBox1">
                                <div class="row">
                                    <div class="col-md-2">
                                        <span class="ThmLft">
                                            <img src="../../../../assets/images/blogthumb.png" alt="">
                                        </span>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="tcx1">Blog<br/>Article</div>
                                    </div>
                                </div>
                            </div>
                            <div class="blgItemBox2">How to continue selling your service within the new AI regulatory framework</div>
                            <div class="blgItemBox3">As you may know, the EU has published its proposal for new regulations on the ethical use of AI in financial services.[1] But how.... do you keep selling your products and services in a way that’s compliant with the new rules?</div>
                            <div class="blgItemBox4">
                                <a routerLink="/blogdetailpage">Discover More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="blogpad">
                    <div class="blogItm1 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s" style="background-image: url(../../../../assets/images/blog1.jpg);">
                        <div class="blogItm1Content">
                            <div class="blgItemBox1">
                                <div class="row">
                                    <div class="col-md-2">
                                        <span class="ThmLft">
                                            <img src="../../../../assets/images/blogthumb.png" alt="">
                                        </span>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="tcx1">Blog<br/>Article</div>
                                    </div>
                                </div>
                            </div>
                            <div class="blgItemBox2">How to continue selling your service within the new AI regulatory framework</div>
                            <div class="blgItemBox3">As you may know, the EU has published its proposal for new regulations on the ethical use of AI in financial services.[1] But how.... do you keep selling your products and services in a way that’s compliant with the new rules?</div>
                            <div class="blgItemBox4">
                                <a routerLink="/blogdetailpage">Discover More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
               <div class="blogpad">
                <div class="blogItm1 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s" style="background-image: url(../../../../assets/images/blog2.jpg);">
                    <div class="blogItm1Content">
                        <div class="blgItemBox1">
                            <div class="row">
                                <div class="col-md-2">
                                    <span class="ThmLft">
                                        <img src="../../../../assets/images/blogthumb.png" alt="">
                                    </span>
                                </div>
                                <div class="col-md-7">
                                    <div class="tcx1">Blog<br/>Article</div>
                                </div>
                            </div>
                        </div>
                        <div class="blgItemBox2">How to continue selling your service within the new AI regulatory framework</div>
                        <div class="blgItemBox3">As you may know, the EU has published its proposal for new regulations on the ethical use of AI in financial services.[1] But how.... do you keep selling your products and services in a way that’s compliant with the new rules?</div>
                        <div class="blgItemBox4">
                            <a routerLink="/blogdetailpage">Discover More</a>
                        </div>
                    </div>
                </div>
               </div>
            </div> -->
        </div>
    </div>
    
</div>